import React from 'react';
import Header from './Header';
import HeroSection from './HeroSection';
import TestimonialSection from './TestimonialSection';
import PricingSection from './PricingSection';
import FAQSection from './FAQSection';
import Footer from './Footer';

const Home = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-[#000080] to-[#00004d]">
      <Header />
      <HeroSection />
      <TestimonialSection />
      <PricingSection />
      <FAQSection />
      <Footer />
    </div>
  );
};

export default Home;