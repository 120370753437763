import React, { useState } from 'react';
import { IoAdd, IoRemove } from 'react-icons/io5';
import { FiHelpCircle } from 'react-icons/fi';

const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      category: "General Questions",
      questions: [
        {
          q: "How does the visa consultation process work?",
          a: `Our process follows three simple steps: 
          1. We get on a call, understand about your background, purpose, past trips, and challenges.
          2. We further access and give the list of to-do's that needs to be taken care and prepare for the application.
          3. We do our magic over itinerary and cover letter which covers all the past we discussed and the plan of action.
          `
        },
        {
          q: "Do you fill application form for clients?",
          a: "No, we never recommend any adult to get their application form filled by others, we will not fill, we will review or will answer doubts that come across while you're filling."
        },
        {
          q: "How do I pay for the consultation?",
          a: "We accept Indian cards, UPI, Netbanking and wallets payments securely through Razorpay, we do have support for other currencies too, please reach out to us to know more."
        },
        {
          q: "How to connect and send any query?",
          a: `Mail us at support@viszapp.com or DM on X @viszapp.`
        }
      ]
    },
    {
      category: "Timelines & Success",
      questions: [
        {
          q: "How soon we should consult before our tentative travel dates?",
          a: `Ideally 6 months is best timeline as we get freedom to work on the issues/concerns on application which further improves the
          chances of success for our clients, minimum is like 3 business days before the official appointment day.`
        },
        {
          q: "What is the visa approval success rate?",
          a: `From short history from Viszapp, we have seen great results till now, but the success for future clients depends on various factors, but our main aim is to let genuine travellers 
          their right to get visa without breaking their pocket and less stressful/confusing experience.`
        }
      ]
    },
    {
      category: "Support & Guarantees",
      questions: [
        {
          q: "What happens if my visa application is rejected?",
          a: `Please understand that we will do our best, we will ensure that you have the application of highest quality however visas are issued at sole discretion of the issuing country, no one who is legit should be able to guarantee you the visa but we do share the odds before application.`
        },
        {
          q: "How to cancel/reschedule my appointment?",
          a: `Please visit: https://viszapp.com/service-policies for a detailed explanation on the cancellation process. For 
          For rescheduling or any other support regarding the order, just mail us on support@viszapp.com with the order id, we will do our best accomodate the requests.`
        }
      ]
    }
  ];

  return (
    <section className="py-10 bg-[#1C1F2E] relative">
      {/* Background Elements */}
      <div className="absolute inset-0">
        <div className="absolute top-0 right-0 w-96 h-96 bg-teal-500/5 rounded-full filter blur-3xl"></div>
        <div className="absolute bottom-0 left-0 w-96 h-96 bg-purple-500/5 rounded-full filter blur-3xl"></div>
      </div>

      <div className="max-w-4xl mx-auto px-4 relative z-10">
        <div className="text-center mb-16">
          <div className="flex items-center justify-center gap-2 mb-4">
            <FiHelpCircle className="text-teal-400 text-2xl" />
            <span className="text-teal-400 font-medium">FAQ</span>
          </div>
          <h2 className="text-4xl md:text-5xl font-bold text-white mb-4">
            Common Questions
          </h2>
          <p className="text-gray-400 text-lg">
            Everything you need to know about our visa consultation services
          </p>
        </div>

        <div className="space-y-6">
          {faqs.map((category, categoryIndex) => (
            <div key={categoryIndex} className="bg-[#2A3142]/50 rounded-2xl p-6 backdrop-blur-sm">
              <h3 className="text-xl font-semibold text-white mb-4">{category.category}</h3>
              <div className="space-y-4">
                {category.questions.map((faq, index) => {
                  const questionIndex = `${categoryIndex}-${index}`;
                  const isActive = activeIndex === questionIndex;

                  return (
                    <div key={index} className="border-b border-gray-700/50 last:border-0 pb-4 last:pb-0">
                      <button
                        className="w-full flex items-center justify-between text-left transition-colors"
                        onClick={() => setActiveIndex(isActive ? null : questionIndex)}
                      >
                        <span className={`font-medium ${isActive ? 'text-teal-400' : 'text-white'}`}>
                          {faq.q}
                        </span>
                        <span className={`ml-4 p-1 rounded-full transition-colors ${
                          isActive ? 'bg-teal-400/10 text-teal-400' : 'bg-gray-700/30 text-gray-400'
                        }`}>
                          {isActive ? <IoRemove size={18} /> : <IoAdd size={18} />}
                        </span>
                      </button>

                      <div className={`grid transition-all duration-300 ${
                        isActive ? 'grid-rows-[1fr] opacity-100 mt-4' : 'grid-rows-[0fr] opacity-0'
                      }`}>
                        <div className="overflow-hidden">
                          <p className="text-gray-400">
                            {faq.a}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>

        {/* Support CTA */}
        <div className="mt-12 text-center p-6 bg-gradient-to-br from-[#2F3651] to-[#252A3D] rounded-2xl border border-gray-700/50">
          <h3 className="text-xl font-semibold text-white mb-3">
            Still have questions?
          </h3>
          <p className="text-gray-400 mb-6">
            Can't find the answer you're looking for? Our team is here to help.
          </p>
          <button 
            onClick={() => window.open('https://x.com/viszapp', '_blank', 'noopener,noreferrer')}
            className="px-8 py-3 bg-teal-500 hover:bg-teal-400 text-white rounded-xl 
                     font-semibold transition-all duration-300 hover:shadow-lg hover:shadow-teal-500/25"
          >
            Contact Support
          </button>
        </div>
      </div>
    </section>
  );
};

export default FAQSection;