// src/PrivacyPolicy.js
import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
  return (
<>

<Helmet>
        <title>Privacy Policy - Viszapp</title>
        <meta name="description" content="Explains the privacy policy of Viszapp" />
        <meta name="keywords" content="viszapp policies, Viszapp, priyesh sharma, viszapp privacy" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Privacy Policy - Viszapp" />
        <meta property="og:description" content="Explains the privacy policy of Viszapp" />
        <meta property="og:image" content="https://viszapp.com/logo.png" />
        <meta property="og:url" content="https://viszapp.com/login" />
        <meta name="twitter:title" content="Viszapp" />
        <meta name="twitter:description" content="Viszapp Official X" />
        <meta name="twitter:image" content="https://x.com/viszapp/photo" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

    <div className="bg-gradient-to-b from-[#1C1F2E] via-[#2A3142] to-[#2A3142] text-white min-h-screen">
      <Header />
      
      {/* Background Elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute w-full h-full">
          <div className="absolute top-60 right-20 w-72 h-72 bg-teal-500/5 rounded-full filter blur-3xl"></div>
          <div className="absolute bottom-20 left-1/3 w-80 h-80 bg-purple-500/5 rounded-full filter blur-3xl"></div>
        </div>
      </div>
      
      <div className="py-12 sm:px-6 lg:px-8 relative z-10">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-10">
            <span className="inline-block px-4 py-1 mb-4 rounded-full text-teal-400 text-sm font-medium
                          bg-teal-400/10 border border-teal-400/20">
              PRIVACY
            </span>
            <h1 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-white to-gray-300 mb-4">
              Privacy Policy
            </h1>
            <p className="text-gray-400 text-lg max-w-2xl mx-auto">
              How we collect, use, and protect your information
            </p>
          </div>
          
          <div className="bg-white/5 backdrop-blur-sm border border-white/10 rounded-xl p-8 shadow-xl">
            <div className="prose prose-lg max-w-none">
              <p className="text-gray-400 mb-4">Last updated: 27th June 2024</p>
              <p className="text-gray-300 mb-4">
                This Privacy Policy describes how Viszapp ("we", "us", or "our") collects, uses, and shares personal information when you use our website https://viszapp.com.
                Please read this policy carefully to understand our practices regarding your personal data and how we will treat it.
              </p>

              <h2 className="text-2xl font-bold mt-8 mb-4 text-white">Information We Collect</h2>
              <p className="text-gray-300 mb-4">
                We collect personal information that you provide to us when you interact with our website. This may include your name, email address, phone number, and any other information you choose to provide.
              </p>

              <h2 className="text-2xl font-bold mt-8 mb-4 text-white">How We Use Your Information</h2>
              <p className="text-gray-300 mb-4">
                We use the information we collect to provide and improve our services, personalize your experience, communicate with you, and ensure the security of our website.
              </p>

              <h2 className="text-2xl font-bold mt-8 mb-4 text-white">Sharing Your Information</h2>
              <p className="text-gray-300 mb-4">
                We do not sell, trade, or otherwise transfer your personal information to outside parties unless we provide you with advance notice, except as described below. The term "outside parties" does not include Viszapp. It also does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential.
              </p>

              <h2 className="text-2xl font-bold mt-8 mb-4 text-white">Security of Your Information</h2>
              <p className="text-gray-300 mb-4">
                We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information.
              </p>

              <h2 className="text-2xl font-bold mt-8 mb-4 text-white">Changes to This Policy</h2>
              <p className="text-gray-300 mb-4">
                We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on this page. You are advised to review this Privacy Policy periodically for any changes.
              </p>

              <h2 className="text-2xl font-bold mt-8 mb-4 text-white">Contact Us</h2>
              <p className="text-gray-300 mb-4">
                If you have any questions about this Privacy Policy, please contact us at <a href="mailto:support@viszapp.com" className="text-teal-400 hover:text-teal-300 transition-colors">support@viszapp.com</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
    </>
  );
};

export default PrivacyPolicy;