import React from "react";
import { HiOutlineGlobeAlt } from "react-icons/hi";
import { FiArrowRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const HeroSection = () => {
  // Website info
  const siteInfo = {
    siteName: "Viszapp",
    domain: "viszapp.com",
    title: "Expert Visa Application Guidance | Viszapp",
    description:
      "Navigate your visa application with confidence. Our expert consultants ensure a smooth, hassle-free process with personalized support at every step.",
    twitterHandle: "@viszapp",
    // Primary OG image should be 1200x630px for optimal display
    ogImage: "https://viszapp.com/og-images/homepage-og.png",
    logoImage: "https://viszapp.com/logo.png",
    // Current page URL
    currentUrl: "https://viszapp.com",
  };

  return (
    <>
      <Helmet>
        {/* Primary Meta Tags */}
        <title>{siteInfo.title}</title>
        <meta name="description" content={siteInfo.description} />
        <meta
          name="keywords"
          content="visa application, visa guidance, viszapp, visa consultants, travel visa services, schengen visa, us visa, uk visa, canada visa"
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={siteInfo.currentUrl} />
        <meta property="og:site_name" content={siteInfo.siteName} />
        <meta property="og:title" content={siteInfo.title} />
        <meta property="og:description" content={siteInfo.description} />
        <meta property="og:image" content={siteInfo.ogImage} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:locale" content="en_US" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content={siteInfo.twitterHandle} />
        <meta name="twitter:creator" content={siteInfo.twitterHandle} />
        <meta name="twitter:url" content={siteInfo.currentUrl} />
        <meta name="twitter:title" content={siteInfo.title} />
        <meta name="twitter:description" content={siteInfo.description} />
        <meta name="twitter:image" content={siteInfo.ogImage} />

        {/* Additional meta tags */}
        <link rel="canonical" href={siteInfo.currentUrl} />
        <meta name="application-name" content={siteInfo.siteName} />
        <meta name="apple-mobile-web-app-title" content={siteInfo.siteName} />
        <meta name="theme-color" content="#1C1F2E" />
      </Helmet>
      <div className="relative min-h-screen overflow-hidden bg-gradient-to-b from-[#1C1F2E] via-[#2A3142] to-[#2A3142]">
        {/* Animated background elements */}
        <div className="absolute inset-0">
          <div className="absolute top-0 left-0 w-[500px] h-[500px] bg-teal-500/10 rounded-full filter blur-[100px] animate-pulse"></div>
          <div className="absolute bottom-0 right-0 w-[500px] h-[500px] bg-purple-500/10 rounded-full filter blur-[100px] animate-pulse delay-1000"></div>
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[800px] h-[800px] bg-blue-500/10 rounded-full filter blur-[120px] animate-pulse delay-700"></div>
        </div>

        {/* Content */}
        <div className="relative z-10 max-w-7xl mx-auto px-4 pt-32 pb-20">
          <div className="flex flex-col lg:flex-row items-center justify-between gap-12">
            {/* Left side: Main content */}
            <div className="flex-1 text-center lg:text-left">
              <span
                className="inline-flex items-center px-4 py-1.5 rounded-full
                           bg-gradient-to-r from-teal-500/10 to-purple-500/10 
                           border border-teal-500/20 backdrop-blur-sm"
              >
                <span className="text-teal-400 text-sm font-medium">
                  Trusted by 500+ travelers
                </span>
              </span>

              <h1 className="mt-8 text-4xl sm:text-5xl lg:text-6xl font-bold text-white leading-tight">
                Your Journey Begins
                <span className="block mt-2 text-transparent bg-clip-text bg-gradient-to-r from-teal-400 to-purple-400">
                  With Expert Visa Guidance
                </span>
              </h1>

              <p className="mt-6 text-gray-400 text-lg sm:text-xl max-w-xl">
                Navigate your visa application with confidence. Our expert
                consultants ensure a smooth, hassle-free process with
                personalized support at every step.
              </p>

              <div className="mt-10 flex flex-col sm:flex-row gap-4 justify-center lg:justify-start">
                <Link to="/login">
                  <button
                    className="inline-flex items-center px-8 py-3 rounded-xl
                               bg-gradient-to-r from-teal-500 to-teal-400
                               text-white font-semibold transition-all
                               hover:shadow-lg hover:shadow-teal-500/25 hover:scale-105"
                  >
                    Start Your Application
                    <FiArrowRight className="ml-2" />
                  </button>
                </Link>
                <Link to="/how-it-works">
                  <button
                    className="inline-flex items-center px-8 py-3 rounded-xl
                               bg-[#2F3651] text-white font-semibold
                               border border-gray-700 hover:border-teal-500/50
                               transition-all hover:bg-[#252A3D]"
                  >
                    Learn More
                    <HiOutlineGlobeAlt className="ml-2" />
                  </button>
                </Link>
              </div>
            </div>

            {/* Right side: Stats */}
            <div className="flex-1 grid grid-cols-2 gap-4 w-full max-w-md">
              {[
                {
                  title: "Success Rate",
                  value: "99%",
                  description: "Visa Approval",
                },
                {
                  title: "Countries",
                  value: "6+",
                  description: "Supported Destinations",
                },
                {
                  title: "Business Days",
                  value: "3-4",
                  description: "Request Processing Time",
                },
                {
                  title: "Happy Clients",
                  value: "600+",
                  description: "And Growing",
                },
              ].map((stat, index) => (
                <div
                  key={index}
                  className="p-6 rounded-2xl bg-gradient-to-br from-[#2F3651] to-[#252A3D]
                            border border-gray-700/50 hover:border-teal-500/50
                            transition-all hover:shadow-lg hover:shadow-teal-500/10"
                >
                  <h3 className="text-3xl font-bold text-white">
                    {stat.value}
                  </h3>
                  <p className="text-teal-400 font-medium mt-1">{stat.title}</p>
                  <p className="text-gray-400 text-sm mt-1">
                    {stat.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Bottom wave */}
        <div className="absolute bottom-0 left-0 right-0">
          <svg
            viewBox="0 0 1440 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 100L60 87.5C120 75 240 50 360 47.5C480 45 600 65 720 77.5C840 90 960 95 1080 87.5C1200 80 1320 60 1380 50L1440 40V200H1380C1320 200 1200 200 1080 200C960 200 840 200 720 200C600 200 480 200 360 200C240 200 120 200 60 200H0V100Z"
              fill="#2A3142"
              fillOpacity="0.2"
            />
          </svg>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
