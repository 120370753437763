import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setPricing, setCountryPlan } from '../redux/slices/pricingSlice';
import background from "../assets/loginbackground.webp";
import FinalPage from './FinalPage';

const ChoosePrice = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showMobileSidebar, setShowMobileSidebar] = useState(false);

  const applications = useSelector(state => state.visa.applications);
  const selectedCountries = useSelector(state => state.visa.selectedCountries);
  const selectedPlans = useSelector(state => state.pricing.selectedPlans);
  // const totalAmount = useSelector(state => state.pricing.totalAmount);

  const countriesNeedingPlan = selectedCountries.filter(country => 
    country !== 'United States of America Visa' && country !== 'Japan Visa'
  );

  useEffect(() => {
    // Set default plans for all countries
    selectedCountries.forEach(country => {
      if (!selectedPlans[country]) {
        dispatch(setCountryPlan({ country, plan: 'A-Z prep' }));
        dispatch(setPricing({
          country,
          amount: country === 'United States of America Visa' ? 1500 : 
                 country === 'Japan Visa' ? 1350 : 2300,
          plan: 'A-Z prep'
        }));
      }
    });
  }, [selectedCountries, selectedPlans, dispatch]);

  const CountryTab = ({ country, applications, appointmentState, plan }) => {
    // Find the first application for this country to get appointment details
    const firstApp = applications[0];
    const appointment = firstApp?.appointment;
  
    // Determine the plan display
    let displayPlan = plan;
    let displayAmount = '';

    if (country === 'United States of America Visa') {
      displayPlan = 'A-Z prep'; // Fixed plan for USA
      displayAmount = '1,500';
    } else if (country === 'Japan Visa') {
      displayPlan = 'A-Z prep'; // Fixed plan for Japan
      displayAmount = '1,350';
    } else if (country === 'Australia Visa') {
      displayAmount = plan === 'Get Reviewed' ? '1,250' : '2,600';
    } else {
      displayAmount = plan === 'Get Reviewed' ? '1,250' : '2,300';
    }
  
    return (
      
      <div className={`
        w-full p-4 text-left border-b hover:bg-gray-50
        ${displayPlan ? 'text-green-600' : 'text-gray-700'}
      `}>
        
        <div className="flex flex-col">
          <span className="font-medium">{country}</span>
          <span className="text-sm text-gray-500">
            {country === 'United States of America Visa' 
              ? `${applications.length} Person${applications.length > 1 ? 's' : ''}`
              : `${applications.length} Couple${applications.length > 1 ? 's' : ''} + Dependent`}
          </span>
          {appointment?.date && (
            <div className="text-sm text-gray-500 mt-1">
              <div>{new Date(appointment.date).toLocaleDateString()}</div>
              <div>
                {new Date(appointment.time).toLocaleTimeString([], {
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: true
                })}
              </div>
            </div>
          )}
          {displayPlan && (
            <div className="text-sm font-medium mt-1">
              Plan: {displayPlan}
              <div className="text-[#191983]">
                ₹{displayAmount}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    const checkData = () => {
      const hasValidApplications = Object.values(applications).every(app => 
        app.documents && 
        app.documents.nationality && 
        app.documents.occupation &&
        app.appointment && 
        app.appointment.date && 
        app.appointment.time
      );

      if (!hasValidApplications || !selectedCountries.length) {
        navigate('/visaSelection');
        return;
      }
      setIsLoading(false);
    };

    checkData();
  }, [applications, selectedCountries, navigate]);

  

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-[#000080] to-[#00003D]">
        <div className="bg-white p-8 rounded-lg shadow-lg">
          <p className="text-lg text-gray-700">Loading...</p>
        </div>
      </div>
    );
  }

  const PricingCard = ({ title, subtitle, features }) => (
    <div className="bg-white rounded-2xl p-2 m-2 lg:h-[320px] w-full md:w-80 flex flex-col">
      <h2 className="text-lg sm:text-xl text-center font-bold mb-1" style={{ color: '#000080' }}>{title}</h2>
      <p className="text-black text-center font-semibold text-sm sm:text-base mb-4">{subtitle}</p>
      <div className="flex-grow">
        {features.map((feature, index) => (
          <div key={index} className="flex items-center mb-2">
            <img className="mr-2" src={require('../assets/checked.png')} alt="" style={{ width: 18, height: 18 }} />
            <p className="text-gray-900 text-sm sm:text-base ml-1">{feature}</p>
          </div>
        ))}
      </div>
    </div>
  );

  const getReviewedFeatures = [
    'Documents review, feedback and trip analysis',
    'ONE meeting only',
    'Refusal Insights',
    'Current trends on the targeted countries',
    'Email support expires after 48hrs from the scheduled consultation'
  ];

  const azPrepFeatures = [
    `Complete Document Prep`,
    'Draft complete itinerary for your trip',
    'One dedicated call, with additional calls available as needed',
    'Exclusive Offer on Travel Insurance',
    'Travel Guidance(Travel, Flights, CC Recommendations, etc.)',
    'Unlimited emails and telegram support'
  ];

  const handleOptionChange = (option) => {
    selectedCountries.forEach(country => {
      // Skip USA and Australia as they have fixed plans
      if (country === 'United States of America Visa' || country === 'Japan Visa') {
        return;
      }
      
      const amount = option === 'Get Reviewed' ? 1250 : 2300;
      
      // Update pricing and plan for other countries
      dispatch(setPricing({
        country,
        amount,
        plan: option
      }));
      dispatch(setCountryPlan({ country, plan: option }));
    });
  };

  const getPrice = () => {
    let total = 0;
    selectedCountries.forEach(country => {
      const countryApps = Object.values(applications)
        .filter(app => app.country === country);
      const numApplications = countryApps.length;
      
      if (country === 'United States of America Visa') {
        total += 1500 * numApplications; // USD applications are per person
      } else if (country === 'Japan Visa') {
        total += 1350 * numApplications; // Japan applications are per person
      } else if (country === 'Australia Visa') {
        const plan = selectedPlans[country];
        if (plan === 'Get Reviewed') {
          total += 1250 * numApplications;
        } else if (plan === 'A-Z prep') {
          total += 2600 * numApplications; 
        }
      } else {
        // For other countries
        const plan = selectedPlans[country];
        if (plan === 'Get Reviewed') {
          total += 1250 * numApplications;
        } else if (plan === 'A-Z prep') {
          total += 2300 * numApplications;
        }
      }
    });
    return total;
  };

const getCurrentPlan = () => {
  // If no countries need a plan (only USA/Australia selected), return empty
  if (countriesNeedingPlan.length === 0) return '';

  // Get the first plan from countries that need a plan
  const firstPlanCountry = countriesNeedingPlan[0];
  const firstCountryPlan = selectedPlans[firstPlanCountry];
  
  // Check if all countries that need a plan have the same plan
  const allSamePlan = countriesNeedingPlan.every(country => 
    selectedPlans[country] === firstCountryPlan
  );

  return allSamePlan ? firstCountryPlan : '';
};

const handleSubmit = () => {
  // Get total number of applications across all countries
  // const totalApplications = Object.values(applications).length;
  // console.log("=== SUBMISSION DETAILS ===");
  // console.log("Total Applications:", totalApplications);
  // console.log("Total Countries Selected:", selectedCountries.length);
  // console.log("Countries with Plans:", Object.keys(selectedPlans).length);
  
  // console.log("\n=== DETAILED BREAKDOWN BY COUNTRY ===");
  // selectedCountries.forEach(country => {
  //   const application = applications[country];
  //   console.log(`\n📍 ${country} Details:`);
  //   console.log({
  //     quantity: selectedPlans[country]?.quantity || 0,
  //     planType: selectedPlans[country]?.planType || 'No plan selected',
  //     planPrice: selectedPlans[country]?.price || 0,
  //     documents: {
  //       uploaded: !!application?.documents,
  //       details: application?.documents || 'No documents uploaded'
  //     },
  //     appointment: {
  //       scheduled: !!application?.appointment,
  //       details: application?.appointment || 'No appointment scheduled'
  //     },
  //     personalInfo: {
  //       name: application?.name || 'Not provided',
  //       email: application?.email || 'Not provided',
  //       phone: application?.phone || 'Not provided'
  //     }
  //   });
  // });

  // First check
  if (selectedCountries.length > 0) {
    // console.log("\n✅ First check passed: Has selected countries");
    
    // Second check
    if (Object.keys(selectedPlans).length === selectedCountries.length) {
      // console.log("✅ Second check passed: Equal number of plans and countries");
      
      // Validate each application
      const allApplicationsValid = Object.values(applications).every(app => {
        const isValid = selectedPlans[app.country] && 
                       app.documents &&
                       app.appointment;
        
        // console.log(`\n🔍 Validation for ${app.country}:`, {
        //   hasPlan: {
        //     status: !!selectedPlans[app.country],
        //     plan: selectedPlans[app.country] || 'None'
        //   },
        //   hasDocuments: {
        //     status: !!app.documents,
        //     files: app.documents || 'None'
        //   },
        //   hasAppointment: {
        //     status: !!app.appointment,
        //     details: app.appointment || 'None'
        //   },
        //   overallStatus: isValid ? '✅ Valid' : '❌ Invalid'
        // });
        
        return isValid;
      });

      // console.log("\n=== FINAL VALIDATION ===");
      // console.log("All applications valid:", allApplicationsValid ? '✅ Yes' : '❌ No');

      if (allApplicationsValid) {
        setModalOpen(true);
      }
    } else {
      console.log("\n❌ Second check failed: Plans count doesn't match countries count");
    }
  } else {
    console.log("\n❌ First check failed: No selected countries");
  }
};

const isAllCountriesHavePlans = selectedCountries.every(country => selectedPlans[country]);

return (
  <div className="relative min-h-screen">
     {/* Background gradient and image */}
     <div
      className="absolute inset-0"
      style={{
        background: "linear-gradient(rgba(0, 0, 128, 1), rgba(0, 0, 61, 1))",
      }}
    >
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{ backgroundImage: `url(${background})`, opacity: 0.08 }}
      ></div>
    </div>

    {/* Main content */}
    <div className="relative z-10 flex min-h-screen">

    {/* Mobile Toggle Button */}
{showMobileSidebar ? (
  <button
    className="md:hidden fixed top-4 right-4 z-50 bg-white p-3 rounded-lg shadow-lg flex items-center justify-center"
    onClick={() => setShowMobileSidebar(false)}
  >
    <span className="text-2xl font-bold">✕</span>
  </button>
) : (
  <button
    className="md:hidden fixed top-4 left-4 z-50 bg-white p-3 rounded-lg shadow-lg flex items-center justify-center"
    onClick={() => setShowMobileSidebar(true)}
  >
    <span className="text-3xl leading-none">☰</span>
  </button>
)}

    {/* Sidebar */}
    <div className={`
      ${showMobileSidebar ? 'fixed inset-0 z-40' : 'hidden'} 
      md:relative md:block
      w-full md:w-80 bg-white shadow-lg
      transition-all duration-300 ease-in-out
    `}>
      <div className="p-4 bg-gray-50 border-b sticky top-0">
        <h3 className="text-lg font-semibold text-gray-800">Selected Countries</h3>
        <p className="text-sm text-gray-500">Application Summary</p>
      </div>

      <div className="divide-y overflow-y-auto" style={{ height: 'calc(100vh - 140px)' }}>
        {selectedCountries.map(country => {
          const countryApps = Object.values(applications)
            .filter(app => app.country === country);
          return (
            <CountryTab
              key={country}
              country={country}
              applications={countryApps}
              appointmentState={applications[countryApps[0]?.id]?.appointment}
              plan={selectedPlans[country]}
            />
          );
        })}
      </div>

      <div className="p-4 border-t bg-white sticky bottom-0">
        <div className="text-sm font-medium text-gray-800">
          Total Amount: ₹{getPrice()}
        </div>
      </div>
    </div>

    {/* Main Content */}
    <div className="flex-1 p-4 md:p-8">
      <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-lg p-6">
        {/* Title */}
        {/* Title */}
<div className="text-center mb-8">
  <div className="flex items-center mb-4">
    <h2 className="text-xl sm:text-2xl font-bold text-[#191983] flex-grow">
      4/4 Select Your Plan
    </h2>
  </div>
  {countriesNeedingPlan.length > 0 && (
    <p className="text-sm text-gray-600">
      Choose a plan for {countriesNeedingPlan.join(', ')}
    </p>
  )}
</div>

        {/* Plan Selection Cards - Only show if there are countries needing plans */}
        {countriesNeedingPlan.length > 0 && (
  <div className="flex flex-col sm:flex-row justify-center items-center sm:space-x-4 mb-6">
    <label className="cursor-pointer w-full sm:w-auto">
      <input
        type="radio"
        name="pricingOption"
        value="Get Reviewed"
        className="hidden"
        checked={getCurrentPlan() === 'Get Reviewed'}
        onChange={() => handleOptionChange('Get Reviewed')}
      />
      <div className={`border-2 rounded-2xl overflow-hidden transition-all duration-200 ${
        getCurrentPlan() === 'Get Reviewed' 
          ? 'border-blue-800 shadow-xl' 
          : 'border-gray-300 hover:border-gray-400'
      }`}>
        <PricingCard
          title="Get Reviewed"
          subtitle="(recommended for experienced folks)"
          features={getReviewedFeatures}
        />
      </div>
    </label>
    <label className="cursor-pointer mt-4 sm:mt-0 w-full sm:w-auto">
      <input
        type="radio"
        name="pricingOption"
        value="A-Z prep"
        className="hidden"
        checked={getCurrentPlan() === 'A-Z prep'}
        onChange={() => handleOptionChange('A-Z prep')}
      />
      <div className={`border-2 rounded-2xl overflow-hidden transition-all duration-200 ${
        getCurrentPlan() === 'A-Z prep' 
          ? 'border-blue-800 shadow-xl' 
          : 'border-gray-300 hover:border-gray-400'
      }`}>
        <PricingCard
          title="A-Z prep"
          subtitle="(new-bie or first timers)"
          features={azPrepFeatures}
        />
      </div>
    </label>
  </div>
)}

        {/* Confirmation and Payment Section */}
        {(isAllCountriesHavePlans || countriesNeedingPlan.length === 0) && (
          <div className="mt-6">
            <div className="flex flex-col items-center">
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={isConfirmed}
                  onChange={() => setIsConfirmed(!isConfirmed)}
                />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 
                             peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full 
                             rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white 
                             after:content-[''] after:absolute after:top-[2px] after:start-[2px] 
                             after:bg-white after:border-gray-300 after:border after:rounded-full 
                             after:h-5 after:w-5 after:transition-all peer-checked:bg-[#191983]"></div>
                <span className="ms-3 text-sm font-medium text-gray-900">
                  {isConfirmed ? "I confirm" : "I don't confirm"}
                </span>
              </label>
              <p className="text-sm text-gray-600 mt-2 max-w-md text-center">
                I have provided complete and accurate information understanding that hiding any relevant 
                details could adversely affect the outcome of our efforts to get you visa smoothly.
              </p>
              {!isConfirmed && (
                <p className="text-sm text-red-500 font-semibold mt-2">
                  You must confirm to proceed with the payment.
                </p>
              )}
            </div>

            {/* Bottom Buttons */}
            <div className="flex justify-between mt-6">
              <button
                type="button"
                onClick={() => navigate("/appointment")}
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
              >
                Back
              </button>
              <button
                type="button"
                className={`bg-[#191983] text-white px-6 py-2 rounded-lg 
                         hover:bg-blue-800 transition-colors
                         ${!isConfirmed ? "opacity-50 cursor-not-allowed" : ""}`}
                onClick={isConfirmed ? handleSubmit : undefined}
                disabled={!isConfirmed}
              >
                Confirm & Pay ₹{getPrice()}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>

    {/* Final Page Modal */}
    <FinalPage isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
  </div>
  </div>
);
};

export default ChoosePrice;