import React, { useEffect, useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import axios from 'axios';
import { Helmet } from 'react-helmet';

const TandC = () => {
  const [htmlContent, setHtmlContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchHtmlFile = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get('https://terms-cup.pages.dev/');
        setHtmlContent(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching HTML file:', error);
        setIsLoading(false);
      }
    };

    fetchHtmlFile();
  }, []);

  return (
    <>
    
<Helmet>
        <title>Terms and Conditions - Viszapp</title>
        <meta name="description" content="Explains the TnCs of Viszapp" />
        <meta name="keywords" content="viszapp policies, Viszapp, priyesh sharma, viszapp privacy" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Terms and Conditions - Viszapp" />
        <meta property="og:description" content="Explains the TnCs of Viszapp" />
        <meta property="og:image" content="https://viszapp.com/logo.png" />
        <meta property="og:url" content="https://viszapp.com/login" />
        <meta name="twitter:title" content="Viszapp" />
        <meta name="twitter:description" content="Viszapp Official X" />
        <meta name="twitter:image" content="https://x.com/viszapp/photo" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

    <div className="bg-gradient-to-b from-[#1C1F2E] via-[#2A3142] to-[#2A3142] min-h-screen">
      <Header />
      
      {/* Background Elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute w-full h-full">
          <div className="absolute top-60 right-20 w-72 h-72 bg-teal-500/5 rounded-full filter blur-3xl"></div>
          <div className="absolute bottom-20 left-1/3 w-80 h-80 bg-purple-500/5 rounded-full filter blur-3xl"></div>
        </div>
      </div>
      
      <div className="container mx-auto px-4 py-16 relative z-10">
        <div className="text-center mb-10 mt-10">
          <span className="inline-block px-4 py-1 mb-4 rounded-full text-teal-400 text-sm font-medium
                        bg-teal-400/10 border border-teal-400/20">
            LEGAL
          </span>
          <h1 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-white to-gray-300 mb-4">
            Terms and Conditions
          </h1>
          <p className="text-gray-400 text-lg max-w-2xl mx-auto">
            Please read these terms carefully before using our services
          </p>
        </div>
        
        {isLoading ? (
          <div className="flex justify-center items-center py-16">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-teal-400"></div>
          </div>
        ) : (
          <div className="bg-white/5 backdrop-blur-sm border border-white/10 rounded-xl p-6 md:p-8 shadow-xl mb-8">
            <div 
              className="terms-content text-gray-300" 
              dangerouslySetInnerHTML={{ __html: htmlContent }} 
            />
          </div>
        )}
      </div>
      
      {/* CSS to override styles in the injected HTML */}
      <style jsx global>{`
        .terms-content h1, 
        .terms-content h2, 
        .terms-content h3, 
        .terms-content h4, 
        .terms-content h5, 
        .terms-content h6 {
          color: white !important;
          margin-top: 1.5rem !important;
          margin-bottom: 1rem !important;
        }
        
        .terms-content p, 
        .terms-content li, 
        .terms-content span {
          color: #d1d5db !important; /* gray-300 */
        }
        
        .terms-content a {
          color: #2dd4bf !important; /* teal-400 */
          text-decoration: none !important;
        }
        
        .terms-content a:hover {
          color: #14b8a6 !important; /* teal-500 */
          text-decoration: underline !important;
        }
        
        .terms-content ul, 
        .terms-content ol {
          margin-left: 1.5rem !important;
          margin-bottom: 1rem !important;
        }
        
        .terms-content li {
          margin-bottom: 0.5rem !important;
        }
        
        .terms-content table {
          border-collapse: collapse !important;
          width: 100% !important;
          margin-bottom: 1rem !important;
        }
        
        .terms-content th, 
        .terms-content td {
          border: 1px solid rgba(255, 255, 255, 0.1) !important;
          padding: 0.75rem !important;
          color: #d1d5db !important;
        }
        
        .terms-content th {
          background-color: rgba(255, 255, 255, 0.05) !important;
          color: white !important;
        }
        
        /* Override any background colors from the original content */
        .terms-content * {
          background-color: transparent !important;
        }
      `}</style>
      
      <Footer />
    </div>
    </>
  );
};

export default TandC;