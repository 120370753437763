import React, { useState, useEffect, useCallback } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { FaQuoteLeft, FaUser } from "react-icons/fa";
import { motion } from "framer-motion";

const TestimonialCard = ({ testimonial }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const maxLength = 150;
  const isLongTestimonial = testimonial.testimonial.length > maxLength;

  const getDisplayText = () => {
    if (!isLongTestimonial || isExpanded) {
      return testimonial.testimonial;
    }
    return testimonial.testimonial.substr(0, testimonial.testimonial.lastIndexOf(' ', maxLength)) + '...';
  };
  
  return (
    <div className="relative group h-full">
      {/* Card glow effect */}
      <div className="absolute inset-0 bg-gradient-to-r from-teal-500/10 to-blue-500/10 
                    rounded-2xl blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-200"/>
      
      {/* Main card container with flex column */}
      <div className="relative flex flex-col h-full min-h-[400px] backdrop-blur-xl bg-white/5 p-8 rounded-2xl
                    border border-white/10 group-hover:border-white/20
                    transition-all duration-200 hover:transform hover:scale-[1.02]">
        
        {/* Content section that can grow/shrink */}
        <div className="flex-grow">
          <FaQuoteLeft className="text-teal-400 text-3xl mb-6" />

          <div className="text-gray-300 text-lg leading-relaxed">
            <p>{getDisplayText()}</p>
            {isLongTestimonial && (
              <button
                onClick={() => setIsExpanded(!isExpanded)}
                className="text-teal-400 hover:text-teal-300 mt-2 text-sm font-medium transition-colors duration-200"
              >
                {isExpanded ? 'Show Less' : 'Read More'}
              </button>
            )}
          </div>
        </div>

        {/* Footer section - fixed at bottom */}
        <div className="mt-auto pt-6 border-t border-white/10">
          <div className="flex items-center">
            <div className="w-12 h-12 rounded-full bg-gradient-to-r from-teal-400 to-blue-400 
                          flex items-center justify-center">
              <FaUser className="text-white text-xl" />
            </div>
            <div className="ml-4 flex-grow">
              <h4 className="text-white font-semibold">{testimonial.name}</h4>
              <p className="text-teal-400 text-sm">{testimonial.location}</p>
            </div>
            <div className="flex gap-1">
              {[...Array(testimonial.rating)].map((_, i) => (
                <span key={i} className="text-amber-400">★</span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TestimonialsSection = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  const testimonials = [
    {
      id: "1",
      name: "Shrey Gupta",
      location: "Applied for Finland Visa - Oct'24",
      testimonial:
        "I can't express how happy I was to finally get my Schengen visa after facing two refusals in the past. Priyesh accurately identified the issues and guided me on the exact steps needed to secure the visa. With his clear instructions, the entire process turned out to be a breeze!",
      rating: 5,
    },
    {
      id: "2",
      name: "SHAILENDRA SAHU",
      location: "Applied for Austria Visa - Sept'24",
      testimonial:
        "The overall experience was amazing. Visa was approved within 3 days of application. Couldn't have asked for more.",
      rating: 5,
    },

    {
      id: "3",
      name: "Karan Dhanak",
      location: "Applied for France Visa - Nov'24",
      testimonial:
        "Priyesh and team were always very supportive and helped us at each stage. Will definitely seek help from them for future travels as well.",
      rating: 5,
    },

    {
      id: "4",
      name: "Annoymous",
      location: "Applied for Netherlands Visa - Nov'24",
      testimonial:
        "Priyesh has got me out of a 2 month long turmoil. I owe him, his guidance & confidence in getting me out of a visa refusal misery. Any one in doubt about a refusal - reach him out, not your agent!",
      rating: 5,
    },

    {
      id: "5",
      name: "Dr. Rajkumar R A",
      location: "Applied for Italy Visa - Nov'24",
      testimonial:
        "I got my Schengen visa for italy through priyesh. He was very prompt in clearing queries and his support in preparing documents for visa appointment was excellent. I would definitely utilise his services for future tours .",
      rating: 5,
    },

    {
      id: "6",
      name: "Mohammad",
      location: "Applied for UK Visa - Dec'24",
      testimonial:
        "Brilliant experience. Priyesh bhai replied with all my questions. Extremely underrated service and criminally under priced. Team has replied with patience and now I received my UK Visa. Thanks a lot",
      rating: 5,
    },

    {
      id: "7",
      name: "HARTEG SINGH",
      location: "Applied for Schengen Visa - Dec'24",
      testimonial:
        "A seamless experience with Mr. Priyesh. A knowledgeable person who always guides us to the right direction. Wish to see you grow more and still stay grounded as a person.",
      rating: 5,
    },

    {
      id: "8",
      name: "RAKSHIT SHARMA",
      location: "Applied for UK Visa - Dec'24",
      testimonial:
        "I'm thrilled with the Viszapp staff. I'm really grateful for Priyesh! He was incredibly helpful. Very pleased with the services. They managed me get my visa approved. Highly recommended.",
      rating: 5,
    },

    {
      id: "9",
      name: "Prateek Prasad",
      location: "Applied for Ireland Visa - Sept'24",
      testimonial:
        "Priyesh is a genius. Worth every penny",
      rating: 5,
    },


    {
      id: "10",
      name: "Shriniwas Jayant Gorane",
      location: "Applied for France Visa - Jan'25",
      testimonial:
        "The overall experience was a new learning for me. Kudos to Priyesh, Ridam and Team Viszapp for their dedicated services for our France Trip. This is my First International Trip and first is always special and memorable. Team Viszapp listened to all my queries, answered them and draft the perfect itinerary and assisted me in all the documentation part with the strongest cover letter. This resulted in swift approval from the France Embassy without any issues. I am recommending Viszapp to all my Clientele and Friends. Keep it up Guys.",
      rating: 5,
    },


    {
      id: "11",
      name: "Karan Raghuvanshi",
      location: "Applied for UK Visa - Jan'25",
      testimonial:
        "Priyesh from Viszapp provided excellent visa assistance for my UK tourist visa. My profile was a bit complicated, but he put in great effort to simplify it and present it effectively to the UK Embassy. His expertise and dedication made the process smooth, and I truly appreciate his support. Highly recommended for hassle-free visa assistance!",
      rating: 5,
    },


    {
      id: "12",
      name: "Prateek Prasad",
      location: "Applied for Greece - Dec'24",
      testimonial:
        "Talk about undercharging and over delivering 👌🏼",
      rating: 5,
    },

    {
      id: "13",
      name: "Himanshu Navalgaria",
      location: "Applied for - Feb'25",
      testimonial:
        "My experience with Priyesh was nothing short of fantastic. It was a smooth journey throughout. He made it very easy for us to get the visa. thank you so much Viszapp team for your commendable efforts. Will 10/10 recommend.",
      rating: 5,
    },


    {
      id: "14",
      name: "Ameya Vichare",
      location: "Applied for UK & Switzerland Visa - Dec'24",
      testimonial:
        "Got both the UK visa and Schengen visa in a month for both me and my wife at a nominal fee. Thanks for all the help to Priyesh!",
      rating: 5,
    },

    {
      id: "15",
      name: "Chandra Sekhar Kartha",
      location: "Applied for Switzerland Visa - Feb'25",
      testimonial:
        "Very good experience with Priyesh and his team. Priyesh was very helpful with providing his inputs on docs required for the visa, cover letter etc. Made the process for the Schengen visa for myself and my parents smooth. Would recommend and go with him again in the future.",
      rating: 5,
    },

    {
      id: "16",
      name: "Abhishek Bhatia",
      location: "Applied for France Visa - Dec'24",
      testimonial:
        `Can't recommend Viszapp enough. I approached Priyesh via X in last week of November and asked if it's possible to plan a Christmas/New Year Europe trip for a family of 4. Within 2 weeks I had a multi entry visa from the France embassy for all of us.

        Priyesh was super helpful at every step, from guiding on application, creating a cover letter, itinerary. He helped us at each and every step and kept answering my stupid questions throughout.
        Strongly recommend anyone who's planning on applying any visa to go via Viszapp.`,
      rating: 5,
    },

    {
      id: "17",
      name: "Selvarathinam Senthil",
      location: "Applied for Japan Visa - Feb'25",
      testimonial:
        `I approached them for Japan visa.
        Even tough they didn't have slot they accommodated my request considering my urgency and helped in preparing documents for the visa.
        That was a great help for me considering the short time i gave them.
        I can recommend them blindly to any of my friends considering the professionalism on thier services.
        Thank you priyesh`,
      rating: 5,
    },

    {
      id: "18",
      name: "Lalit Khemnani",
      location: "Applied for Switzerland Visa - Feb'25",
      testimonial:
        `I recently used Viszapp services for visa assistance, and I couldn't be happier. Priyesh was incredibly helpful throughout the entire visa process, Patiently answering all my questions, guiding me through the requirements and ensuring all my documents were in order, Ridam helped me plan the itinerary based on my interest and preferences and thanks to their expertise we all got the Visa. Overall a fantastic experience .`,
      rating: 5,
    },
    

  ];

  const itemsPerPage = {
    desktop: 3,
    tablet: 2,
    mobile: 1
  };

  const getCurrentItemsPerPage = useCallback(() => {
    if (typeof window !== 'undefined') {
      if (window.innerWidth >= 1024) return itemsPerPage.desktop;
      if (window.innerWidth >= 768) return itemsPerPage.tablet;
      return itemsPerPage.mobile;
    }
    return itemsPerPage.desktop;
  }, [itemsPerPage.desktop, itemsPerPage.tablet, itemsPerPage.mobile]);

  const [currentItemsPerPage, setCurrentItemsPerPage] = useState(getCurrentItemsPerPage());

  useEffect(() => {
    const handleResize = () => {
      setCurrentItemsPerPage(getCurrentItemsPerPage());
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [getCurrentItemsPerPage]);

  const getTotalPages = useCallback(() => {
    return Math.ceil(testimonials.length / currentItemsPerPage);
  }, [testimonials.length, currentItemsPerPage]);

  const nextTestimonial = useCallback(() => {
    const maxIndex = getTotalPages() - 1;
    setActiveIndex((prev) => (prev >= maxIndex ? 0 : prev + 1));
  }, [getTotalPages]);

  const prevTestimonial = () => {
    const maxIndex = getTotalPages() - 1;
    setActiveIndex((prev) => (prev <= 0 ? maxIndex : prev - 1));
  };
  useEffect(() => {
    let interval;
    if (!isPaused) {
      interval = setInterval(nextTestimonial, 7000);
    }
    return () => clearInterval(interval);
  }, [isPaused, nextTestimonial]);

  return (
    <section className="py-20 relative overflow-hidden">
      {/* Background with enhanced patterns */}
      <div className="absolute inset-0 bg-gradient-to-br from-[#1a1f35] to-[#111827]">
        <div className="absolute inset-0 opacity-30">
          <div 
            className="absolute w-full h-full"
            style={{
              backgroundImage: "radial-gradient(circle at 2px 2px, rgba(255,255,255,0.05) 1px, transparent 0)",
              backgroundSize: "32px 32px"
            }}
          />
        </div>
        {/* Animated gradient orbs */}
        <div className="absolute inset-0">
          {[...Array(3)].map((_, i) => (
            <div
              key={i}
              className="absolute rounded-full blur-3xl opacity-20 animate-pulse"
              style={{
                background: i === 0 ? "linear-gradient(to right, #0ea5e9, #2dd4bf)" 
                         : i === 1 ? "linear-gradient(to right, #8b5cf6, #6366f1)"
                         : "linear-gradient(to right, #14b8a6, #0d9488)",
                width: "40%",
                height: "40%",
                top: `${30 * i}%`,
                left: `${25 * i}%`,
                animation: `pulse ${6 + i}s infinite`
              }}
            />
          ))}
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 relative z-10">
        {/* Section header */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <span className="inline-block px-4 py-1 mb-4 rounded-full text-teal-400 text-sm font-medium
                        bg-teal-400/10 border border-teal-400/20">
            TESTIMONIALS
          </span>
          <h2 className="text-4xl md:text-5xl font-bold mb-4 bg-clip-text text-transparent 
                       bg-gradient-to-r from-white to-gray-300">
            Success Stories from Our Clients
          </h2>
          <p className="text-gray-400 text-lg max-w-2xl mx-auto">
            Join hundreds of satisfied travelers who trusted us with their visa journey
          </p>
        </motion.div>

        {/* Testimonials carousel */}
        <div className="relative" 
             onMouseEnter={() => setIsPaused(false)}
             onMouseLeave={() => setIsPaused(false)}>
          {/* Navigation buttons */}
          <button
  onClick={prevTestimonial}
  className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-4 md:-translate-x-8 z-10
           bg-white/5 hover:bg-white/10 text-white p-3 rounded-full
           backdrop-blur-xl transition-colors duration-200 border border-white/10
           hover:border-white/20"
>
  <AiOutlineArrowLeft size={24} />
</button>


<button
  onClick={nextTestimonial}
  className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-4 md:translate-x-8 z-10
           bg-white/5 hover:bg-white/10 text-white p-3 rounded-full
           backdrop-blur-xl transition-colors duration-200 border border-white/10
           hover:border-white/20"
>
  <AiOutlineArrowRight size={24} />
</button>

          {/* // Fix the slider's transform style */}
          <div className="overflow-hidden">
      <div
        className="flex transition-transform duration-200 ease-out"
        style={{ 
          transform: `translateX(-${activeIndex * 100}%)`,
        }}
      >
        {testimonials.map((testimonial, index) => (
          <motion.div
            key={testimonial.id}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: index * 0.1 }}
            className={`px-4 ${
              currentItemsPerPage === 3 ? 'w-1/3' :
              currentItemsPerPage === 2 ? 'w-1/2' :
              'w-full'
            }`}
            style={{ flex: '0 0 auto' }}
          >
            <TestimonialCard testimonial={testimonial} />
          </motion.div>
        ))}
      </div>
    </div>

    {/* Progress indicators */}
    <div className="flex justify-center gap-2 mt-8">
      {[...Array(getTotalPages())].map((_, index) => (
        <button
          key={index}
          onClick={() => setActiveIndex(index)}
          className="group relative"
        >
          <div className={`h-2 rounded-full transition-all 
                        ${activeIndex === index 
                          ? 'w-8 bg-gradient-to-r from-teal-400 to-blue-400' 
                          : 'w-2 bg-white/20'}`}>
            <div className="absolute inset-0 bg-gradient-to-r from-teal-400 to-blue-400 
                         rounded-full blur opacity-50 group-hover:opacity-100 transition-opacity"/>
          </div>
        </button>
      ))}
    </div>


        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;