import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import { HiOutlineGlobeAlt } from 'react-icons/hi';

const Header = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const navLinks = [
    { title: "Home", path: "/" },
    { title: "About Founders", path: "/know-us" },
    { title: "How it Works", path: "/how-it-works" },
    // { title: "Offers", path: "/offers" },
    { title: "Blogs", path: "/blogs" }
  ];

  return (
    <header 
      className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
        isScrolled 
          ? 'bg-[#1C1F2E]/80 backdrop-blur-lg shadow-lg' 
          : 'bg-transparent'
      }`}
    >
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex items-center justify-between h-20">
          {/* Logo */}
          <Link to="/" className="flex items-center space-x-2">
            <HiOutlineGlobeAlt className="text-teal-400 text-3xl" />
            <span className="text-2xl font-bold text-white">Viszapp</span>
          </Link>

          {/* Desktop Navigation */}
          <nav className="hidden md:flex items-center space-x-8">
            {navLinks.map((link) => (
              <Link
                key={link.title}
                to={link.path}
                className="text-gray-300 hover:text-teal-400 transition-colors duration-300
                         relative group py-2"
              >
                {link.title}
                <span className="absolute bottom-0 left-0 w-full h-0.5 bg-teal-400 scale-x-0 
                               group-hover:scale-x-100 transition-transform duration-300" />
              </Link>
            ))}
          </nav>

          {/* Launch App Button */}
          <div className="hidden md:block">
            <Link 
              to="/login"
              className="px-6 py-2 rounded-xl bg-gradient-to-r from-teal-500 to-teal-400
                       text-white font-semibold transition-all duration-300
                       hover:shadow-lg hover:shadow-teal-500/25 hover:scale-105"
            >
              Launch App
            </Link>
          </div>

          {/* Mobile Menu Button */}
          <button 
            className="md:hidden text-white text-2xl p-2 rounded-lg
                     hover:bg-white/10 transition-colors"
            onClick={() => setSidebarOpen(true)}
          >
            <AiOutlineMenu />
          </button>
        </div>
      </div>

      {/* Mobile Sidebar */}
      <div 
        className={`fixed inset-0 bg-black/50 backdrop-blur-sm z-50 md:hidden transition-opacity duration-300
                   ${sidebarOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}`}
        onClick={() => setSidebarOpen(false)}
      >
        <div 
          className={`absolute right-0 top-0 bottom-0 w-64 bg-[#2A3142] transform transition-transform duration-300
                     ${sidebarOpen ? 'translate-x-0' : 'translate-x-full'}`}
          onClick={e => e.stopPropagation()}
        >
          <div className="p-5">
            <button 
              className="text-white text-2xl p-2 rounded-lg hover:bg-white/10 transition-colors"
              onClick={() => setSidebarOpen(false)}
            >
              <AiOutlineClose />
            </button>

            <nav className="mt-8 space-y-4">
              {navLinks.map((link) => (
                <Link
                  key={link.title}
                  to={link.path}
                  className="block text-gray-300 hover:text-teal-400 transition-colors duration-300 py-2"
                  onClick={() => setSidebarOpen(false)}
                >
                  {link.title}
                </Link>
              ))}
              <Link
                to="/login"
                className="block mt-4 px-6 py-2 rounded-xl bg-gradient-to-r from-teal-500 to-teal-400
                         text-white font-semibold text-center"
                onClick={() => setSidebarOpen(false)}
              >
                Launch App
              </Link>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;