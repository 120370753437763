import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Helmet } from 'react-helmet';

const Cancellation = () => {
  return (
    <>
      <Helmet>
        <title>Service Policies - Viszapp</title>
        <meta name="description" content="Explains the policies for Upgrades, Cancellations, and Refunds of services a client opts for" />
        <meta name="keywords" content="viszapp policies, Viszapp, priyesh sharma, viszapp cancellation, viszapp support, viszapp refund" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Service Policies - Viszapp" />
        <meta property="og:description" content="Explains the policies for Upgrades, Cancellations, and Refunds of services a client opts for." />
        <meta property="og:image" content="https://viszapp.com/logo.png" />
        <meta property="og:url" content="https://viszapp.com/login" />
        <meta name="twitter:title" content="Viszapp" />
        <meta name="twitter:description" content="Viszapp Official X" />
        <meta name="twitter:image" content="https://x.com/viszapp/photo" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <Header />
      <div className="bg-gradient-to-b from-[#1C1F2E] via-[#2A3142] to-[#2A3142] text-white py-12 sm:px-6 lg:px-8 min-h-screen">
        {/* Background Elements */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute w-full h-full">
            <div className="absolute top-60 right-20 w-72 h-72 bg-teal-500/5 rounded-full filter blur-3xl"></div>
            <div className="absolute bottom-20 left-1/3 w-80 h-80 bg-purple-500/5 rounded-full filter blur-3xl"></div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto relative z-10">
          <div className="text-center mb-10 mt-10 ">
            <span className="inline-block px-4 py-1 mb-4 rounded-full text-teal-400 text-sm font-medium
                            bg-teal-400/10 border border-teal-400/20">
              SERVICE POLICIES
            </span>
            <h1 className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-white to-gray-300 mb-4">
              Upgrades, Cancellations, and Refunds
            </h1>
            <p className="text-gray-400 text-lg max-w-2xl mx-auto">
              We strive to maintain transparency and fairness in all our service arrangements
            </p>
          </div>

          <div className="bg-white/5 backdrop-blur-sm border border-white/10 rounded-xl p-8 md:p-10 mb-8 shadow-xl">
            <div className="prose prose-lg max-w-none text-gray-300">
              <p>
                This document outlines Viszapp's comprehensive policies regarding service upgrades, cancellations, and refunds. 
                We strive to maintain transparency and fairness in all our service arrangements.
              </p>

              <h2 className="text-2xl font-bold mt-8 mb-4 text-white">Upgrade Options</h2>
              <p>
                If you have purchased our Get Reviewed plan, you have the opportunity to upgrade to our comprehensive A-Z Prep Plan 
                within 7 days of your initial purchase. This upgrade option is available for a flat fee of ₹1,250 and can be accessed 
                directly through your order details section. For additional information or assistance with the upgrade process, 
                please contact our support team at{' '}
                <a href="mailto:support@viszapp.com" className="text-teal-400 hover:text-teal-300 transition-colors">
                  support@viszapp.com
                </a>.
              </p>
              
              <h2 className="text-2xl font-bold mt-8 mb-4 text-white">Cancellation Process</h2>
              <p>
                To initiate a service cancellation, please email{' '}
                <a href="mailto:support@viszapp.com" className="text-teal-400 hover:text-teal-300 transition-colors">
                  support@viszapp.com
                </a>{' '}
                with the following information:
              </p>
              <ul className="list-disc pl-6 mt-2 mb-4 space-y-2">
                <li>Your order ID</li>
                <li>Full name</li>
                <li>Service package details</li>
                <li>Reason for cancellation</li>
              </ul>

              <h2 className="text-2xl font-bold mt-8 mb-4 text-white">Cancellation Terms and Fees</h2>
              <div>
                <p className="mb-4">
                  1. For cancellations prior to any scheduled calls, a 20% service fee will be deducted from the refund amount.
                </p>
                <p className="mb-4">
                  2. After attending the first call, cancellations must be requested within 24 hours with a valid reason to be 
                  eligible for a refund. In such cases, a flat cancellation fee of ₹1,250 will apply.
                </p>
                <p className="mb-4">
                  3. No-show Policy:
                  <ul className="list-disc pl-6 mt-2 space-y-2">
                    <li>A no-show is defined as arriving more than 10 minutes late to a scheduled call</li>
                    <li>No refunds will be provided for no-shows</li>
                    <li>Each no-show will result in the deduction of one session from your package</li>
                    <li>One no-show will forfeit the A to Z package</li>
                    <li>One no-show will forfeit the Get Reviewed plan</li>
                    <li>Rescheduling may be considered if requested within 24 hours of the missed appointment</li>
                  </ul>
                </p>
              </div>

              <h2 className="text-2xl font-bold mt-8 mb-4 text-white">Refund Processing</h2>
              <p>
                Eligible refund requests must be submitted within one working day of service cancellation. All refunds are subject 
                to review and final approval by Viszapp. Please note that a standard 5% processing fee, charged by the payment 
                gateway, will be deducted from all refund amounts.
              </p>

              <h2 className="text-2xl font-bold mt-8 mb-4 text-white">Contact Information</h2>
              <p>
                For any questions or clarifications regarding these policies, please reach out to our support team at{' '}
                <a href="mailto:support@viszapp.com" className="text-teal-400 hover:text-teal-300 transition-colors">
                  support@viszapp.com
                </a>.
              </p>

              <p className="mt-8 text-sm text-gray-400">
                Policy effective as of February 26, 2025. Terms and conditions are subject to change with prior notice.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Cancellation;