import React, { useEffect, useState, useCallback } from 'react';
import Header from './Header';
import Footer from './Footer';
import person1 from '../assets/priyesh/p2.jpeg';
import person2 from '../assets/priyesh/p1.jpeg';
import person3 from '../assets/priyesh/p3.jpeg';
import person6 from '../assets/ridam/r3.webp';
import xIcon from '../assets/x.png';
import emailIcon from '../assets/email.png';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

const Knowus = () => {
  const [scrollIndex, setScrollIndex] = useState(0);
  const phrases = ["Honesty", "Transparency", "Expertise"];

  const updateScrollIndex = useCallback(() => {
    setScrollIndex((prevIndex) => (prevIndex + 1) % phrases.length);
  }, [phrases.length]);

  useEffect(() => {
    const timer = setInterval(updateScrollIndex, 2000);
    return () => clearInterval(timer);
  }, [updateScrollIndex]);

  const teamMembers = [
    {
      name: "Priyesh Sharma",
      role: "Founder, Visa Specialist",
      description: `Engineer by profession and an avid traveler by passion who has been to 25+ countries has helped 1000s of people to receive their visa, also a professional with expertise in payments background, he reads and write's visa like very few could and can make your travel unforgettable without losing an arm or a leg. He has been doing it since more than 5 years and very active on X.`,
      images: [person1, person2, person3],
      social: {
        x: 'https://x.com/Kenu73',
        email: 'mailto:priyesh@viszapp.com',
      },
    },
    {
      name: "Ridam Jain",
      role: "Co-founder, Engineer & Itinerary Specialist",
      description: `Software Engineer, finance geek, stutter, previously worked at PoS providers in Ireland, has been freelacing since 2018, he is currently handling the tech and product development at Viszapp.`,
      images: [person6, person6, person6],
      social: {
        x: 'https://x.com/rhythmnibjia',
        email: 'mailto:ridam@viszapp.com',
      },
    },
  ];

  const [imageIndex, setImageIndex] = useState(new Array(teamMembers.length).fill(0));

  const handleHover = (index) => {
    setImageIndex(prevIndexes => {
      const newIndexes = [...prevIndexes];
      newIndexes[index] = (newIndexes[index] + 1) % 3;
      return newIndexes;
    });
  };

  const handleSwipe = (index) => {
    setImageIndex(prevIndexes => {
      const newIndexes = [...prevIndexes];
      newIndexes[index] = (newIndexes[index] + 1) % 3;
      return newIndexes;
    });
  };

  return (
    <>
    <Helmet>
        <title>About Founders - Viszapp</title>
        <meta name="description" content="Talking about each founder background and their social media, email to connect. - Viszapp" />
        <meta name="keywords" content="viszapp founders, Viszapp, priyesh sharma, ridam jain" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="About Founders - Viszapp" />
        <meta property="og:description" content="Detail about the Viszapp founders" />
        <meta property="og:image" content="https://viszapp.com/logo.png" />
        <meta property="og:url" content="https://viszapp.com/login" />
        <meta name="twitter:title" content="Viszapp" />
        <meta name="twitter:description" content="Viszapp Official X" />
        <meta name="twitter:image" content="https://x.com/viszapp/photo" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
    
    <div className="bg-gradient-to-b from-[#1C1F2E] via-[#2A3142] to-[#2A3142] min-h-screen text-white">
      <Header />
      
      {/* Background Elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute w-full h-full">
          <div className="absolute top-60 right-20 w-72 h-72 bg-teal-500/5 rounded-full filter blur-3xl"></div>
          <div className="absolute bottom-20 left-1/3 w-80 h-80 bg-purple-500/5 rounded-full filter blur-3xl"></div>
        </div>
      </div>

      <div className="container mx-auto px-4 py-20 relative z-10">
        <div className="flex flex-col md:flex-row items-start justify-between">
          <div className="md:w-1/3 mb-12 md:mb-0 md:sticky md:top-20 self-start">
            <div className="text-center md:text-left mt-10">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <span className="inline-block px-4 py-1 mb-4 rounded-full text-teal-400 text-sm font-medium
                              bg-teal-400/10 border border-teal-400/20">
                  OUR TEAM
                </span>
                <h1 className="text-4xl md:text-5xl font-bold text-white mb-4">
                  Meet the Team
                </h1>
                <p className="text-xl md:text-2xl text-gray-400 mb-2">
                  that believes in
                </p>
              </motion.div>
              
              <div className="h-16 overflow-hidden">
                <div
                  className="transition-transform duration-500 ease-in-out"
                  style={{ transform: `translateY(-${scrollIndex * 4}rem)` }}
                >
                  {phrases.map((phrase, index) => (
                    <p key={index} className="text-2xl md:text-3xl text-transparent bg-clip-text bg-gradient-to-r from-teal-400 to-teal-300 font-semibold h-16 flex items-center justify-center md:justify-start">
                      {phrase}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="md:w-2/3 grid grid-cols-1 md:grid-cols-2 gap-8 mt-10">
            {teamMembers.map((member, index) => (
              <motion.div 
                key={index}
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                className="relative h-full"
              >
                <div className="relative group h-full">
                  {/* Card glow effect */}
                  <div className="absolute inset-0 bg-gradient-to-r from-teal-500/10 to-purple-500/10 
                                rounded-2xl blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-500"/>
                  
                  {/* Card content with fixed height */}
                  <div className="relative h-full backdrop-blur-sm bg-[#2F3651]/80 p-6 md:p-8 rounded-2xl
                                border border-white/10 group-hover:border-teal-500/30
                                transition-all duration-300 flex flex-col">
                    <div 
                      className="relative overflow-hidden mb-6 rounded-xl"
                      onMouseEnter={() => handleHover(index)}
                      onTouchStart={() => handleSwipe(index)}
                    >
                    
                      <motion.img 
                        src={member.images[imageIndex[index]]} 
                        alt={member.name} 
                        className="w-full h-64 object-cover transition-transform duration-300 group-hover:scale-105" 
                        whileHover={{ scale: 1.05 }}
                      />
                    </div>
                    
                    {/* Content area with flex-grow to push social links to bottom */}
                    <div className="flex flex-col flex-grow">
                      <h2 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-white to-gray-300">
                        {member.name}
                      </h2>
                      <p className="text-teal-400 mb-3">{member.role}</p>
                      
                      {/* Description with min-height for consistency */}
                      <div className="flex-grow">
                        <p className="text-gray-300 leading-relaxed">{member.description}</p>
                      </div>
                      
                      {/* Social links at bottom */}
                      <div className="flex space-x-4 mt-6 pt-4 border-t border-white/10">
                        <a 
                          href={member.social.x}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="p-2 bg-white/5 rounded-lg hover:bg-white/10 
                                   text-gray-300 hover:text-white transition-colors duration-200"
                        >
                          <img src={xIcon} alt="Twitter Icon" className="w-5 h-5 invert opacity-70 hover:opacity-100" />
                        </a>
                        <a 
                          href={member.social.email}
                          className="p-2 bg-white/5 rounded-lg hover:bg-white/10 
                                   text-gray-300 hover:text-white transition-colors duration-200"
                        >
                          <img src={emailIcon} alt="Email Icon" className="w-5 h-5 invert opacity-70 hover:opacity-100" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
    </>
  );
};

export default Knowus;