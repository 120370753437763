import React, { useState, useEffect } from "react";
import background from "../assets/loginbackground.webp";
import { useNavigate, useLocation } from "react-router-dom";
import showPasswordIcon from "../assets/show-password.png";
import hidePasswordIcon from "../assets/hide.png";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet-async";
import config from "../config";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import axios from "axios";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const location = useLocation();
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [forgotemail, setForgotEmail] = useState("");

  const handleForgotPasswordClick = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  // Check for the 'verified' query parameter and show the toast message
  useEffect(() => {
    const params = new URLSearchParams(location.search);

    // Assuming the backend only appends 'verified=true' during a 302 redirect
    if (params.get("verified") === "true") {
      Toast.fire({
        icon: "success",
        title: "Email verified, you can login now!",
      });
    }
    // eslint-disable-next-line
  }, [location.search]);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleGoogleSignUp = async (credentialResponse) => {
    const decoded = jwtDecode(credentialResponse.credential);
    const { email, sub, name } = decoded;

    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      },
    });

    try {
      const loginResponse = await axios.post(
        `${config.apiBaseUrl}/api/users/google-signin`,
        { email, gid: sub, name }
      );

      if (loginResponse.status === 200 && loginResponse.data.token) {
        Toast.fire({
          icon: "success",
          title: loginResponse.data.message,
        });
        localStorage.setItem("accessToken", loginResponse.data.token);
        navigate("/dashboard");
      } else {
        // Handle unexpected successful response without token
        Toast.fire({
          icon: "error",
          title: "Login successful but no token received",
        });
      }
    } catch (error) {
      // console.error('Error during Google Sign Up:', error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.status === 401 || error.response.status === 403) {
          Toast.fire({
            icon: "error",
            title: error.response.data.message || "Authentication failed",
          });
        } else if (error.response.status === 500) {
          Toast.fire({
            icon: "error",
            title: "Server error. Please try again later.",
          });
        } else if (error.response.status === 404) {
          Toast.fire({
            icon: "error",
            title: error.response.data.message || "Authentication failed",
          });
          navigate("/signup");
        } else {
          Toast.fire({
            icon: "error",
            title: error.response.data.message || "An error occurred",
          });
        }
      } else if (error.request) {
        // The request was made but no response was received
        Toast.fire({
          icon: "error",
          title:
            "No response from server. Please check your internet connection.",
        });
      } else {
        // Something happened in setting up the request that triggered an Error
        Toast.fire({
          icon: "error",
          title: "An unexpected error occurred",
        });
      }
    }
  };
  const handleLogin = async (event) => {
    // console.log("hereeewa");
    event.preventDefault();
    setError("");

    // Form validation
    if (!email.trim() || !password.trim()) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });

      Toast.fire({
        icon: "error",
        title: "Please enter both email and password",
      });

      return; // Exit the function early if validation fails
    }

    try {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });

      const response = await fetch(`${config.apiBaseUrl}/api/users/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password, mode: "signup" }),
      });

      const data = await response.json();
      // console.log(data);

      if (!response.ok) {
        // Handle different error scenarios
        if (data.status === 401 || data.status === 403 || data.status === 500) {
          Toast.fire({
            icon: "error",
            title: data.message || "Authentication failed",
          });
        } else {
          Toast.fire({
            icon: "error",
            title: data.message || "An error occurred",
          });
        }
        throw new Error(data.message || "Login failed");
      } else if (response.ok) {
        // If login is successful
        Toast.fire({
          icon: "success",
          title: "Login Successful",
        });

        const token = data.token;

        // Store the token in localStorage
        localStorage.setItem("accessToken", token);

        // Redirect to the dashboard
        navigate("/dashboard");
      }
    } catch (error) {
      console.error("Login error:", error);
      // This will only run if there's a network error or if we manually throw an error
      Toast.fire({
        icon: "error",
        title: error.message || "An unexpected error occurred",
      });
      setError(error.message || "An unexpected error occurred");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(forgotemail);
    try {
      const response = await fetch(`${config.apiBaseUrl}/forgot`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: forgotemail }),
      });

      const data = await response.json();

      if (response.ok) {
        Toast.fire({
          icon: "success",
          title: data.message || "Check your email to reset your password.",
        });
        setForgotEmail("");
      } else {
        Toast.fire({
          icon: "error",
          title:
            data.message || "Something went wrong. Please try again later.",
        });
      }
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to send reset email. Please check your network connection and try again.",
      });
    }

    setShowPopup(false); // Close popup after submission
  };

  return (

    <>
      <Helmet>
        <title>Login - Viszapp</title>
        <meta name="description" content="Login to your Viszapp account securely. Access your dashboard and manage your visa applications with ease." />
        <meta name="keywords" content="viszapp login, Viszapp, priyesh sharma, schengen visa, UK visa, US visa" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Login - Viszapp" />
        <meta property="og:description" content="Login to your Viszapp account securely." />
        <meta property="og:image" content="https://viszapp.com/logo.png" />
        <meta property="og:url" content="https://viszapp.com/login" />
        <meta name="twitter:title" content="Viszapp" />
        <meta name="twitter:description" content="Viszapp Official X" />
        <meta name="twitter:image" content="https://x.com/viszapp/photo" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

    <div
      className="flex flex-col items-center justify-center min-h-screen relative"
      style={{
        background: "linear-gradient(rgba(0, 0, 128, 1), rgba(0, 0, 61, 1))",
      }}
    >
      <div
        className="absolute inset-0 bg-cover bg-center opacity-50"
        style={{ backgroundImage: `url(${background})`, opacity: 0.08 }}
      ></div>
      <div className="relative text-center">
        <h2 className="text-2xl font-bold text-white mb-6">Viszapp</h2>
        <h3 className="text-3xl font-semibold text-white mb-11">Login</h3>
      </div>
      <div className="relative z-10 bg-white p-5 rounded-xl shadow-lg max-w-md w-full">
        <div className="m-7">
          <div className="flex justify-center mb-3">
            <GoogleLogin
              onSuccess={handleGoogleSignUp}
              onError={() => {
                console.log("Google Login Failed");
              }}
              uxMode="redirect"
              redirectUri={`${window.location.origin}/dashboard`}
            />
          </div>
          <p className="text-center text-gray-600 mb-1">Or</p>
          <form onSubmit={handleLogin}>
            <div className="mb-4">
              <label className="block text-black mb-2" htmlFor="email">
                Email
              </label>
              <input
                className="w-full p-2 border border-gray-300 rounded-md"
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />
            </div>

            <div className="mb-4">
              <label className="block text-black mb-2" htmlFor="password">
                Password
              </label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  className="w-full p-3 border rounded-lg focus:outline-none focus:border-blue-500"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  type="button"
                  className="absolute right-3 top-3"
                  onClick={toggleShowPassword}
                >
                  {showPassword ? (
                    <img
                      src={showPasswordIcon}
                      alt="Show Password"
                      className="h-7 w-7"
                    />
                  ) : (
                    <img
                      src={hidePasswordIcon}
                      alt="Hide Password"
                      className="h-6 w-6"
                    />
                  )}
                </button>
              </div>
            </div>
            <button
              className="w-full bg-blue-700 text-white p-2 mt-3 rounded-md font-semibold"
              type="submit"
            >
              Login now
            </button>
          </form>
          <div className="flex justify-center my-4">
            <button
              onClick={handleForgotPasswordClick}
              className="text-blue-800"
            >
              Forgot Password
            </button>
          </div>
          <div className="text-center mt-4">
            <p className="text-black text-sm">
              By Signing in, you agree to the{" "}
              <a href="/terms" className="text-blue-900">
                T&C
              </a>
              ,{" "}
              <a href="/service-policies" className="text-blue-900">
                Service Policies
              </a>
              , and{" "}
              <a href="/privacy" className="text-blue-900">
                Privacy Policy
              </a>
              .
            </p>
          </div>
          {error && <p className="text-red-500 mt-4">{error}</p>}
          <div className="text-center mt-6">
            <p className="text-black">
              Don't Have An Account?{" "}
              <a href="/signup" className="text-blue-900">
                Sign Up
              </a>
            </p>
          </div>
          <div className="text-center mt-6">
            <p className="text-black">
              Missing Home?{" "}
              <a href="/" className="text-blue-900">
                Home
              </a>
            </p>
          </div>
        </div>
      </div>

      {/* Forgot Password Popup */}
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center z-50">
          <div className="relative bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
            <button
              onClick={closePopup}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 transition text-2xl font-semibold"
            >
              &times;
            </button>
            <form onSubmit={handleSubmit} className="space-y-4">
              <h2 className="text-2xl font-semibold text-center text-gray-800 mb-4">
                Forgot Password
              </h2>
              <p className="text-gray-600 text-center mb-2">
                Enter your email to receive a password reset link
              </p>
              <div>
                <label className="block text-gray-700 mb-2" htmlFor="email">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  value={forgotemail}
                  onChange={(e) => setForgotEmail(e.target.value)}
                  className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                  placeholder="Email"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full bg-blue-700 text-white p-3 rounded-md font-semibold hover:bg-blue-800 transition duration-200"
              >
                Send Email
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

export default Login;
