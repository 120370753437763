import React, { useState } from "react";
import background from "../assets/loginbackground.webp";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Disclaimer from "../shared/Disclaimer";
import {
  setCountryPlan,
  setPricing,
  removeCountryPlan,
} from "../redux/slices/pricingSlice";
import { addCountry, removeCountry } from "../redux/slices/visaSlice";

const VisaSelection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const applications = useSelector((state) => state.visa.applications);
  const [error, setError] = useState("");
  const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(false);

  // Helper function to get all selected visas with their quantities
  const getSelectedVisas = () => {
    const visaCounts = {};
    Object.values(applications).forEach((app) => {
      if (!visaCounts[app.country]) {
        visaCounts[app.country] = 1;
      } else {
        visaCounts[app.country]++;
      }
    });
    return visaCounts;
  };

  const selectedVisas = getSelectedVisas();

  const handleClick = () => {
    if (Object.keys(selectedVisas).length === 0) {
      setError("*Please select at least one Visa!");
    } else {
      navigate("/attachDocuments");
    }
  };

  const handleVisaSelect = (visaName) => {
    if (!selectedVisas[visaName]) {
      dispatch(addCountry({ country: visaName, quantity: 1 })); // Fixed parameter name

      if (visaName === "Schengen Visa") {
        setIsDisclaimerOpen(true);
      }
    }
  };

  const handleQuantityChange = (visaName, newQuantity) => {
    if (newQuantity <= 0) {
      // Remove country completely from all slices
      dispatch(removeCountry(visaName)); // Removes from visa slice
      dispatch(removeCountryPlan(visaName)); // Removes from pricing slice
      setError("");
      return;
    }

    if (newQuantity > 3) {
      setError("Maximum 3 applications allowed for one country in one booking");
      setTimeout(() => setError(""), 3000);
      return;
    }

    // For quantity changes, first remove all existing data
    dispatch(removeCountry(visaName));
    dispatch(removeCountryPlan(visaName));

    // Then add new quantity with fresh data
    dispatch(addCountry({ country: visaName, quantity: newQuantity }));

    // Set default plan if it's USA or Japan
    if (
      visaName === "United States of America Visa" ||
      visaName === "Japan Visa" ||
      visaName === "Australia Visa"
    ) {
      if (
        visaName === "United States of America Visa" ||
        visaName === "Japan Visa"
      ) {
        dispatch(setCountryPlan({ country: visaName, plan: "A-Z prep" }));
        dispatch(
          setPricing({
            country: visaName,
            amount: visaName === "United States of America Visa" ? 1500 : 1350,
            plan: "A-Z prep",
          })
        );
      } else {
        // For Australia, don't set any default plan as it can have both plans
        dispatch(setCountryPlan({ country: visaName, plan: "A-Z prep" }));
        dispatch(
          setPricing({
            country: visaName,
            amount: 2600,
            plan: "A-Z prep",
          })
        );
      }
    }
    setError("");
  };

  const VisaOptions = [
    {
      name: "Schengen Visa",
      image: require("../assets/schegenvisa.png"),
      startingPrice: "₹1250",
      plans: [1250, 2300],
    },
    {
      name: "Canada Visa",
      image: require("../assets/canadavisa.png"),
      startingPrice: "₹1250",
      plans: [1250, 2300],
    },
    {
      name: "United Kingdom Visa",
      image: require("../assets/unitedkingdom.jpg"),
      startingPrice: "₹1250",
      plans: [1250, 2300],
    },
    {
      name: "Ireland Visa",
      image: require("../assets/ireland.jpg"),
      startingPrice: "₹1250",
      plans: [1250, 2300],
    },
    {
      name: "Australia Visa",
      image: require("../assets/australia.jpg"),
      startingPrice: "₹1250",
      plans: [1250, 2600],
    },
    {
      name: "Japan Visa",
      image: require("../assets/japan.jpg"),
      startingPrice: "₹1350",
      plans: [1350],
    },
    {
      name: "United States of America Visa",
      image: require("../assets/usa.jpg"),
      startingPrice: "₹1500",
      plans: [1500],
      comingSoon: true, // Add this property
    },
  ];

  const VisaCard = ({
    visa,
    isSelected,
    onSelect,
    quantity,
    onQuantityChange,
  }) => (
    <div
      className={`border-2 rounded-lg overflow-hidden transition-all duration-300 relative ${
        isSelected ? "border-blue-700 shadow-xl" : "border-gray-300"
      }`}
    >
      {/* Add coming soon overlay */}
      {visa.comingSoon && (
        <div className="absolute inset-0 bg-gray-900/70 z-10 flex flex-col items-center justify-center">
          <div className="relative transform">
            <div className="bg-[#191983] text-white px-6 py-2 rounded-full font-medium tracking-wide rotate-[0deg] shadow-lg">
              Coming Soon
            </div>
          </div>
          <p className="text-white text-sm mt-4 px-4 text-center">
            We're working on making this service available
          </p>
        </div>
      )}

      {/* Make the entire card non-interactive if coming soon */}
      <div
        className={`${
          visa.comingSoon ? "opacity-75 pointer-events-none" : "cursor-pointer"
        }`}
      >
        <div onClick={!visa.comingSoon ? onSelect : undefined}>
          <img
            src={visa.image}
            alt={visa.name}
            className="w-full h-48 object-cover"
          />
          <div className="p-4">
            <div className="font-semibold text-center mb-2">{visa.name}</div>
            {visa.comingSoon ? (
              <div className="text-sm text-gray-400 text-center">
                <span className="blur-sm select-none opacity-50">₹0000</span>
              </div>
            ) : (
              <div className="text-sm text-gray-600 text-center">
                Starting from {visa.startingPrice}
              </div>
            )}
          </div>
        </div>

        {isSelected && !visa.comingSoon && (
          <div className="p-4 pt-0">
            <div className="flex flex-col gap-2">
              <div className="flex items-center justify-between bg-gray-50 p-2 rounded">
                <button
                  onClick={() => onQuantityChange(quantity - 1)}
                  className="w-8 h-8 flex items-center justify-center bg-white rounded border text-gray-600 hover:bg-gray-100"
                >
                  -
                </button>
                <div className="text-center">
                  <div className="font-medium">{quantity}</div>
                  <div className="text-xs text-gray-500">
                    {visa.name === "United States of America Visa"
                      ? `${quantity} Person${quantity > 1 ? "s" : ""}`
                      : quantity === 1
                      ? "1 Applicant/Couple + Dependent"
                      : `${quantity} Applicants/Couples + Dependent`}
                  </div>
                </div>
                <button
                  onClick={() => onQuantityChange(quantity + 1)}
                  className="w-8 h-8 flex items-center justify-center bg-white rounded border text-gray-600 hover:bg-gray-100"
                >
                  +
                </button>
              </div>
              <button
                onClick={() => onQuantityChange(0)}
                className="text-red-600 text-sm hover:text-red-700 font-medium transition-colors mt-1"
              >
                Remove Selection
              </button>
              <div className="text-xs text-gray-500 text-center">
                {visa.name === "United States of America Visa"
                  ? "*Includes DS-160 review and interview prep for each person"
                  : "*Dependent documentation included at no extra cost"}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen relative"
      style={{
        background: "linear-gradient(rgba(0, 0, 128, 1), rgba(0, 0, 61, 1))",
      }}
    >
      <Disclaimer
        isOpen={isDisclaimerOpen}
        onClose={() => setIsDisclaimerOpen(false)}
      />
      <div
        className="absolute inset-0 bg-cover bg-center opacity-50"
        style={{ backgroundImage: `url(${background})`, opacity: 0.08 }}
      ></div>
      <div className="relative z-10 bg-white p-6 sm:p-8 rounded-lg shadow-lg max-w-7xl w-full mx-4 sm:mx-0">
        <div className="absolute left-4 top-4">
          <button onClick={() => navigate("/dashboard")} className="p-2">
            <img
              src={require("../assets/back-button.png")}
              alt="previous"
              className="w-6 h-6"
            />
          </button>
        </div>

        <div className="text-center mb-8">
          <h2 className="text-xl sm:text-2xl font-bold text-[#191983] mb-2">
            Choose Your Visa Destination
          </h2>
          <p className="text-gray-600 text-sm">
            Select multiple countries and specify number of primary applicants
          </p>
          <div className="mt-3 bg-blue-50 p-3 rounded-md border border-blue-100 text-sm text-gray-700 font-bold">
            <p className="mb-1">Application Guidelines:</p>
            <p>
              • Each application can include one primary Applicant/Couple +
              Dependent children under 18 years
            </p>
            <p>• "Couple" refers to legally married pairs only</p>
            <p>
              • Friends traveling together or individuals applying separately
              require separate applications
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mb-8">
          {VisaOptions.map((visa) => (
            <div key={visa.name} className="min-w-[300px]">
              <VisaCard
                visa={visa}
                isSelected={!!selectedVisas[visa.name]}
                onSelect={() => handleVisaSelect(visa.name)}
                quantity={selectedVisas[visa.name] || 0}
                onQuantityChange={(qty) => handleQuantityChange(visa.name, qty)}
              />
            </div>
          ))}
        </div>

        {Object.keys(selectedVisas).length > 0 && (
          <div className="text-center mb-6">
            <div className="font-medium text-gray-700 mb-2">
              Selected Applications:{" "}
              {Object.entries(selectedVisas).map(([name, count], index) => {
                const isUSA = name === "United States of America Visa";
                return (
                  <span key={name}>
                    {name} (
                    {isUSA
                      ? `${count} Person${count > 1 ? "s" : ""}`
                      : `${count} Applicant${count > 1 ? "s" : ""}/Couple${
                          count > 1 ? "s" : ""
                        } + Dependent`}
                    ){index < Object.keys(selectedVisas).length - 1 ? ", " : ""}
                  </span>
                );
              })}
            </div>
          </div>
        )}

        {error && <div className="text-red-500 text-center mb-4">{error}</div>}

        <div className="flex justify-center">
          <button
            onClick={handleClick}
            className="bg-[#191983] text-white py-3 px-8 rounded-lg text-lg font-medium 
                     hover:bg-blue-800 transition-colors disabled:bg-gray-400"
            disabled={Object.keys(selectedVisas).length === 0}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default VisaSelection;
