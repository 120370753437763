import React from 'react';
import { Link } from 'react-router-dom';
import { FiTwitter, FiInstagram, FiMail, FiMapPin,  } from 'react-icons/fi';
import { HiOutlineGlobeAlt } from 'react-icons/hi';
import { FaGoogle } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="pt-8 bg-[#1C1F2E] relative">
      {/* Background Elements */}
      <div className="absolute inset-0">
        <div className="absolute bottom-0 right-0 w-96 h-96 bg-teal-500/5 rounded-full filter blur-3xl"></div>
        <div className="absolute top-0 left-0 w-96 h-96 bg-purple-500/5 rounded-full filter blur-3xl"></div>
      </div>

      <div className="relative">
        {/* <CallToAction /> */}

        <div className="max-w-7xl mx-auto px-4 pb-12">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-12 md:gap-8">
            {/* Brand Column */}
            <div className="md:col-span-1">
              <div className="flex items-center gap-2 mb-6">
                <HiOutlineGlobeAlt className="text-teal-400 text-3xl" />
                <span className="text-2xl font-bold text-white">Viszapp</span>
              </div>
              <p className="text-gray-400 mb-6">
                Simplifying visa applications with expert guidance and personalized support.
              </p>
            </div>

            {/* Quick Links */}
            <div>
  <h3 className="text-white font-semibold mb-6">Quick Links</h3>
  <ul className="space-y-4 list-none">
  {[
    { name: 'Home', path: '/' },
    { name: 'About Founders', path: '/know-us' },
    { name: 'How it Works', path: '/how-it-works' },
    { name: 'Blogs', path: '/blogs' }
  ].map((item) => (
    <li key={item.name}>
      <Link 
        to={item.path}
        className="text-gray-400 hover:text-teal-400 transition-colors"
      >
        {item.name}
      </Link>
    </li>
  ))}
</ul>

</div>

            {/* Legal */}
            <div>
              <h3 className="text-white font-semibold mb-6">Legal</h3>
              <ul className="space-y-4 list-none">
                {[
                  { name: 'Privacy Policy', path: '/privacy' },
                  { name: 'Terms of Service', path: '/terms' },
                  { name: 'Service Policies', path: '/service-policies' }
                ].map((item) => (
                  <li key={item.name}>
                    <Link 
                      to={item.path}
                      className="text-gray-400 hover:text-teal-400 transition-colors"
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            {/* Contact */}
            <div>
              <h3 className="text-white font-semibold mb-6">Contact</h3>
              <ul className="space-y-4">
                <li>
                  <a 
                    href="mailto:support@viszapp.com"
                    className="flex items-center gap-2 text-gray-400 hover:text-teal-400 transition-colors"
                  >
                    <FiMail />
                    <span>support@viszapp.com</span>
                  </a>
                </li>
                <li>
                  <div className="flex items-center gap-2 text-gray-400">
                    <FiMapPin />
                    <span>23, Mahaveer Udhog Nagar, Pali Raj, 306401</span>
                  </div>
                </li>
              </ul>

              {/* Social Links */}
              <div className="flex gap-4 mt-6">
                <a 
                  href="https://twitter.com/viszapp" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="p-2 bg-white/5 rounded-lg hover:bg-white/10 text-gray-400 hover:text-teal-400 transition-colors"
                >
                  <FiTwitter size={20} />
                </a>
                <a 
                  href="https://instagram.com/viszapp" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="p-2 bg-white/5 rounded-lg hover:bg-white/10 text-gray-400 hover:text-teal-400 transition-colors"
                >
                  <FiInstagram size={20} />
                </a>

                <a 
                  href="https://www.google.com/search?q=Viszapp" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="p-2 bg-white/5 rounded-lg hover:bg-white/10 text-gray-400 hover:text-teal-400 transition-colors"
                >
                  <FaGoogle size={20} />
                </a>


              </div>
            </div>
          </div>

          {/* Bottom Bar */}
          <div className="mt-12 pt-8 border-t border-gray-700/50">
            <div className="flex flex-col md:flex-row justify-between items-center gap-4">
              <p className="text-gray-400">© 2025 Viszapp. All rights reserved.</p>
              <p className="text-gray-400">Made with alot of 📑 in 🇮🇳</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;