// src/Blog.js
import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Tweet } from 'react-tweet';
import { Helmet } from 'react-helmet';

const Blog = () => {
  return (
<>
<Helmet>
        <title>X Blogs - Viszapp</title>
        <meta name="description" content="Priyesh Sharma(kenu73) valuable visa posts - Viszapp" />
        <meta name="keywords" content="viszapp priyesh sharma posts, Viszapp, priyesh sharma, viszapp blogs viszapp posts viszapp visa " />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="X Blogs - Viszapp" />
        <meta property="og:description" content="Priyesh Sharma(kenu73) valuable visa posts - Viszapp" />
        <meta property="og:image" content="https://viszapp.com/logo.png" />
        <meta property="og:url" content="https://viszapp.com/login" />
        <meta name="twitter:title" content="Viszapp" />
        <meta name="twitter:description" content="Viszapp Official X" />
        <meta name="twitter:image" content="https://x.com/viszapp/photo" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>


    <div className="bg-gradient-to-b from-[#1C1F2E] via-[#2A3142] to-[#2A3142] min-h-screen">
      <Header />

      {/* Background Elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute w-full h-full">
          <div className="absolute top-60 right-20 w-72 h-72 bg-teal-500/5 rounded-full filter blur-3xl"></div>
          <div className="absolute bottom-20 left-1/3 w-80 h-80 bg-purple-500/5 rounded-full filter blur-3xl"></div>
        </div>
      </div>

      <div className="container mx-auto px-4 py-16 relative z-10">
        <div className="text-center mb-12 mt-10">
          <span className="inline-block px-4 py-1 mb-4 rounded-full text-teal-400 text-sm font-medium
                        bg-teal-400/10 border border-teal-400/20">
            OUR INSIGHTS
          </span>
          <h2 className="text-4xl md:text-5xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-white to-gray-300">
            Latest Updates
          </h2>
          <p className="text-gray-400 text-lg max-w-2xl mx-auto">
            Stay informed with our latest visa tips and travel insights
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="tweet-wrapper bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300">
            <Tweet id="1869654079573803183" />
          </div>
          <div className="tweet-wrapper bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300">
            <Tweet id="1869401939005341930" />
          </div>
          <div className="tweet-wrapper bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300">
            <Tweet id="1869251093378834661" />
          </div>
          <div className="tweet-wrapper bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300">
            <Tweet id="1868587829435027699" />
          </div>
          <div className="tweet-wrapper bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300">
            <Tweet id="1868578826596548848" />
          </div>
          <div className="tweet-wrapper bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300">
            <Tweet id="1768219661534134274" />
          </div>
          <div className="tweet-wrapper bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300">
            <Tweet id="1790617608410276101" />
          </div>
          <div className="tweet-wrapper bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300">
            <Tweet id="1860576174641991914" />
          </div>
          <div className="tweet-wrapper bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300">
            <Tweet id="1864286386628485365" />
          </div>
        </div>
      </div>

      {/* Add some custom CSS to ensure light theme */}
      <style jsx global>{`
        .tweet-wrapper {
          overflow: hidden;
        }
        
        .tweet-wrapper [data-theme="light"] {
          --tweet-theme: light;
          background-color: white !important;
        }
        
        .tweet-wrapper .react-tweet-theme {
          padding: 12px;
          border-radius: 0;
          border: none;
        }
      `}</style>
      
      <Footer />
    </div>
    </>
  );
};

export default Blog;