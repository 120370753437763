import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Helmet } from 'react-helmet';

const HowItWorks = () => {
  return (
    <>
    <Helmet>
        <title>How we Work - Viszapp</title>
        <meta name="description" content="Explaining the process on how we take an application, work on it and provide value - Viszapp" />
        <meta name="keywords" content="viszapp process, Viszapp, priyesh sharma, viszapp steps" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="How we Work - Viszapp" />
        <meta property="og:description" content="Explaining the process on how we take an application, work on it and provide value" />
        <meta property="og:image" content="https://viszapp.com/logo.png" />
        <meta property="og:url" content="https://viszapp.com/login" />
        <meta name="twitter:title" content="Viszapp" />
        <meta name="twitter:description" content="Viszapp Official X" />
        <meta name="twitter:image" content="https://x.com/viszapp/photo" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
    <Header/>
    <div className="relative bg-gradient-to-b from-[#1C1F2E] via-[#2A3142] to-[#2A3142] flex flex-col rounded-md shadow-lg items-center">
      <div className="relative hidden md:block w-full max-w-7xl h-[600px] mx-auto mt-10">
        <div className="absolute inset-0">
          <img
            src={require('../assets/world.png')}
            alt="World Map"
            className="object-cover w-full h-full opacity-20 mix-blend-screen"
          />
        </div>
        <div className="absolute inset-0 flex items-center justify-center">
          <h2 className="text-4xl font-bold w-full max-w-xl text-white text-center p-2">
            5 Steps Process to get the toughest visa the easy way
          </h2>
        </div>

        <div className="absolute top-10 left-1/2 container w-full max-w-64 transform -translate-x-1/2 flex items-center flex-col">
          <div className="bg-gradient-to-r from-teal-500 to-blue-500 text-white w-8 h-8 flex text-xl font-bold items-center justify-center rounded-full">5</div>
          <div className="text-center text-lg mt-2 bg-white/5 backdrop-blur-sm p-2 rounded-lg border border-white/10">
            <p className="font-bold text-white">Bon Voyage:</p>
            <p className="text-gray-300">You're all set for your journey!</p>
          </div>
        </div>

        <div className="absolute container w-full max-w-80 top-32 right-8 flex items-center flex-col">
          <div className="bg-gradient-to-r from-teal-500 to-blue-500 text-white w-8 h-8 flex items-center text-xl font-bold justify-center rounded-full">4</div>
          <div className="text-center text-lg mt-2 bg-white/5 backdrop-blur-sm p-2 rounded-lg border border-white/10">
            <p className="font-bold text-white">Apply to the Required Visa Country:</p>
            <p className="text-gray-300">Apply directly with the Embassy once your application is ready.</p>
          </div>
        </div>

        <div className="absolute container w-full max-w-64 top-1/2 left-10 flex items-center flex-col">
          <div className="bg-gradient-to-r from-teal-500 to-blue-500 text-white w-8 h-8 flex items-center text-xl font-bold justify-center rounded-full">1</div>
          <div className="text-center text-lg mt-2 bg-white/5 backdrop-blur-sm p-2 rounded-lg border border-white/10">
            <p className="font-bold text-white">Fill in Details:</p>
            <p className="text-gray-300">Submit all valid information when booking your consultation.</p>
          </div>
        </div>
    
        <div className="absolute container w-full max-w-64 bottom-1/4 right-10 flex items-center flex-col">
          <div className="bg-gradient-to-r from-teal-500 to-blue-500 text-white w-8 h-8 flex items-center justify-center text-xl font-bold rounded-full">3</div>
          <div className="text-center text-lg mt-2 bg-white/5 backdrop-blur-sm p-2 rounded-lg border border-white/10">
            <p className="font-bold text-white">Personalized Consultation:</p>
            <p className="text-gray-300">Receive tailored advice for your specific case.</p>
          </div>
        </div>

        <div className="absolute bottom-10 left-1/2 container w-full max-w-72 transform -translate-x-1/2 flex items-center flex-col">
          <div className="bg-gradient-to-r from-teal-500 to-blue-500 text-white w-8 h-8 flex items-center justify-center text-xl font-bold rounded-full">2</div>
          <div className="text-center text-lg mt-2 bg-white/5 backdrop-blur-sm p-2 rounded-lg border border-white/10">
            <p className="font-bold text-white">Reviewing Your Details:</p>
            <p className="text-gray-300">We can take upto 72 hours depending on the requests to review your application.</p>
          </div>
        </div>

        <div className="absolute top-16 left-1/4 transform -translate-x-1/2">
          <img 
            src={require('../assets/airplane.png')} 
            alt="Airplane" 
            className="w-16 h-16 transform scale-x-[-1] -rotate-12 filter brightness-0 invert opacity-70"
          />
        </div>
      </div>
    
      {/* Mobile Layout */}
      <div className="md:hidden flex flex-col items-center space-y-6 py-6 px-4">
        <div className="absolute inset-0">
          <img
            src={require('../assets/world.png')}
            alt="World Map"
            className="object-cover w-full h-full opacity-20 mix-blend-screen"
          />
        </div>
        <h2 className="text-2xl font-bold text-white text-center relative z-10">
          5 Steps Process to get the toughest visa the easy way
        </h2>
        
        <div className="flex flex-col items-center px-2 relative z-10 bg-white/5 backdrop-blur-sm p-3 rounded-lg border border-white/10 w-full">
          <div className="bg-gradient-to-r from-teal-500 to-blue-500 text-white w-8 h-8 flex items-center justify-center text-xl font-bold rounded-full">1</div>
          <div className="text-center text-base mt-2">
            <p className="font-bold text-white">Fill in Details:</p>
            <p className="text-gray-300">Submit all valid information when booking your consultation.</p>
          </div>
        </div>

        <div className="flex flex-col items-center px-2 relative z-10 bg-white/5 backdrop-blur-sm p-3 rounded-lg border border-white/10 w-full">
          <div className="bg-gradient-to-r from-teal-500 to-blue-500 text-white w-8 h-8 flex items-center justify-center text-xl font-bold rounded-full">2</div>
          <div className="text-center text-base mt-2">
            <p className="font-bold text-white">Reviewing Your Details:</p>
            <p className="text-gray-300">We take few hours to days depending on the requests to review your application.</p>
          </div>
        </div>

        <div className="flex flex-col items-center px-2 relative z-10 bg-white/5 backdrop-blur-sm p-3 rounded-lg border border-white/10 w-full">
          <div className="bg-gradient-to-r from-teal-500 to-blue-500 text-white w-8 h-8 flex items-center justify-center text-xl font-bold rounded-full">3</div>
          <div className="text-center text-base mt-2">
            <p className="font-bold text-white">Personalized Consultation:</p>
            <p className="text-gray-300">Receive tailored advice for your specific case.</p>
          </div>
        </div>

        <div className="flex flex-col items-center px-2 relative z-10 bg-white/5 backdrop-blur-sm p-3 rounded-lg border border-white/10 w-full">
          <div className="bg-gradient-to-r from-teal-500 to-blue-500 text-white w-8 h-8 flex items-center justify-center text-xl font-bold rounded-full">4</div>
          <div className="text-center text-base mt-2">
            <p className="font-bold text-white">Apply to the Required Visa Country:</p>
            <p className="text-gray-300">Apply directly with the Embassy once your application is ready.</p>
          </div>
        </div>

        <div className="flex flex-col items-center px-2 relative z-10 bg-white/5 backdrop-blur-sm p-3 rounded-lg border border-white/10 w-full">
          <div className="bg-gradient-to-r from-teal-500 to-blue-500 text-white w-8 h-8 flex items-center justify-center text-xl font-bold rounded-full">5</div>
          <div className="text-center text-base mt-2">
            <p className="font-bold text-white">Bon Voyage:</p>
            <p className="text-gray-300">You're all set for your journey!</p>
          </div>
          <div className="p-2">
            <img 
              src={require('../assets/airplane.png')} 
              alt="Airplane" 
              className="w-16 h-16 filter brightness-0 invert opacity-70"
            />
          </div>
        </div>
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default HowItWorks;