import {BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './routes/AppRoutes';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { HelmetProvider } from "react-helmet-async";
import { Helmet } from 'react-helmet';
function App() {
  const siteInfo = {
    siteName: "Viszapp",
    domain: "viszapp.com",
    title: "Expert Visa Application Assistance | Viszapp",
    description: "Navigate your visa application with confidence. Our expert consultants ensure a smooth, hassle-free process with personalized support at every step.",
    twitterHandle: "@viszapp",
    // Primary OG image should be 1200x630px for optimal display
    ogImage: "https://viszapp.com/og-images/homepage-og.png",
    logoImage: "https://viszapp.com/logo.png",
    // Current page URL
    currentUrl: "https://viszapp.com",
  };

  const clientId = process.env.REACT_APP_GOOGLE_CLIENT;
  

  if (!clientId) {
    console.error('GOOGLE_CLIENT_ID is not set in the environment variables');
    // You might want to render an error message or fallback UI here
    return <div>Error: Google Client ID is not configured</div>;
  }

  return (
    <>
    <Helmet>
        {/* Primary Meta Tags */}
        <title>{siteInfo.title}</title>
        <meta name="description" content={siteInfo.description} />
        <meta name="keywords" content="visa application, visa guidance, viszapp, visa consultants, travel visa services, schengen visa, us visa, uk visa, canada visa" />
        <meta name="robots" content="index, follow" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={siteInfo.currentUrl} />
        <meta property="og:site_name" content={siteInfo.siteName} />
        <meta property="og:title" content={siteInfo.title} />
        <meta property="og:description" content={siteInfo.description} />
        <meta property="og:image" content={siteInfo.ogImage} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:locale" content="en_US" />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content={siteInfo.twitterHandle} />
        <meta name="twitter:creator" content={siteInfo.twitterHandle} />
        <meta name="twitter:url" content={siteInfo.currentUrl} />
        <meta name="twitter:title" content={siteInfo.title} />
        <meta name="twitter:description" content={siteInfo.description} />
        <meta name="twitter:image" content={siteInfo.ogImage} />
        
        {/* Additional meta tags */}
        <link rel="canonical" href={siteInfo.currentUrl} />
        <meta name="application-name" content={siteInfo.siteName} />
        <meta name="apple-mobile-web-app-title" content={siteInfo.siteName} />
        <meta name="theme-color" content="#1C1F2E" />
      </Helmet>
    <HelmetProvider>
    <GoogleOAuthProvider clientId={clientId}>
    <Router >
      <AppRoutes/>
    </Router>
    </GoogleOAuthProvider>
    </HelmetProvider>
    </>
  );
}

export default App;
