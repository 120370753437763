import React, { useState } from 'react';
import { FiCheck, FiInfo } from 'react-icons/fi';
import { IoEarthOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';

const PricingSection = () => {
  const [hoveredCard, setHoveredCard] = useState(null);

  const pricingPlans = {
    getReviewed: {
      title: "Get Reviewed",
      price: "1,250",
      description: "Perfect for experienced travelers who need expert review",
      features: [
        { text: "Documents review and feedback", tooltip: "Get expert feedback on your prepared documents." },
        { text: "Trip Analysis", tooltip: "Call to assess your travel plans and potential risks." },
        { text: "Refusal Insights", tooltip: "Understand reasons beyond the refusal letter (if applicable)." },
        { text: "Appointment Prep", tooltip: "Personalized guidance to ensure you're well-prepared." },
        { text: "Country Trends", tooltip: "Stay updated on visa trends for your target destinations." },
        { text: "Single Session", tooltip: "This package includes one appointment only." },
        { text: "Two Days Email Support", tooltip: "Email support available for 48 hours post-consultation." }
      ]
    },
    azPrep: {
      title: "A-Z Prep",
      price: "2,300",
      description: "Complete guidance for first-time applicants",
      features: [
        { text: "Complete Document Prep", tooltip: "We help you gather all required documents, even those missing from your checklist." },
        { text: "Detailed Customized Itinerary", tooltip: "Get a fully planned travel itinerary tailored to your trip." },
        { text: "One Introductory Call", tooltip: "One dedicated call, with additional calls available as needed." },
        { text: "Review Filled Application Form", tooltip: "Only reviewing filled application forms." },
        { text: "Travel Guidance(Travel, Flights, CC Recommendations, etc.)", tooltip: "Get expert suggestions on flights, Airbnb, and payment options." },
        { text: "Exclusive Insurance Discount", tooltip: "Special travel insurance offers to keep you covered." },
        { text: "24/7 Support", tooltip: "Unlimited email and Telegram support for your questions." }
      ]
    }
  };

  const CountryBadge = ({ flag, name, price, isSpecial }) => (
    <div className="flex items-center space-x-4 bg-[#2F3651] rounded-xl p-4 border border-gray-700 w-[400px]
                    hover:border-teal-500/30 transition-colors duration-300">
      <div className="flex items-center justify-center w-12 h-12 bg-[#252A3D] rounded-lg">
        <span className="text-2xl">{flag}</span>
      </div>
      <div>
        <p className="text-white font-medium">{name}</p>
        <p className="text-teal-400 font-semibold">₹{price}</p>
      </div>
    </div>
  );

  return (
    <section className="py-10 relative bg-[#1C1F2E]">
      {/* Background Patterns */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute w-full h-full">
          <div className="absolute top-0 right-0 w-96 h-96 bg-teal-500/5 rounded-full filter blur-3xl"></div>
          <div className="absolute bottom-0 left-0 w-96 h-96 bg-purple-500/5 rounded-full filter blur-3xl"></div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 relative z-10">
        <div className="text-center mb-16">
          <span className="text-teal-400 font-medium mb-4 block">PRICING</span>
          <h2 className="text-4xl md:text-5xl font-bold text-white mb-4">
            Choose Your Success Path
          </h2>
          <p className="text-gray-400 text-lg max-w-2xl mx-auto">
            Transparent pricing with no hidden fees. Select the plan that best suits your needs.
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-8 mb-12">
          {Object.entries(pricingPlans).map(([key, plan]) => (
            <div 
              key={key}
              className={`relative p-8 rounded-2xl transition-all duration-300
                       ${hoveredCard === key 
                         ? 'bg-gradient-to-br from-[#2F3651] to-[#252A3D] scale-105 shadow-xl' 
                         : 'bg-[#2A3142]'}`}
              onMouseEnter={() => setHoveredCard(key)}
              onMouseLeave={() => setHoveredCard(null)}
            >
              <div className="absolute -top-3 right-8">
                {key === 'azPrep' && (
                  <span className="bg-gradient-to-r from-teal-500 to-purple-500 text-white 
                                 px-4 py-1 rounded-full text-sm font-medium">
                    Most Popular
                  </span>
                )}
              </div>

              <h3 className="text-2xl font-bold text-white mb-2">{plan.title}</h3>
              <p className="text-gray-400 mb-4">{plan.description}</p>
              
              <div className="mb-6">
                <span className="text-4xl font-bold text-white">₹{plan.price}</span>
                <span className="text-gray-400 ml-2">/application</span>
              </div>

              {/* Replace the existing features list with this */}
<ul className="space-y-4">
  {plan.features.map((feature, index) => (
    <FeatureWithTooltip key={index} feature={feature} />
  ))}
</ul>
<Link to="/visaSelection">
         <button className="w-full mt-8 py-3 rounded-xl transition-all duration-300
                               bg-gradient-to-r from-teal-500 to-teal-400
                               text-white font-semibold hover:shadow-lg
                               hover:shadow-teal-500/25">
                Choose {plan.title}
              </button>
              </Link>
            </div>
          ))}
        </div>

{/* Country Specific Pricing */}
<div className="bg-[#2A3142] rounded-2xl p-8 border border-gray-700 max-w-2xl mx-auto">
  <div className="flex items-center justify-center gap-3 mb-8">
    <div className="flex items-center justify-center w-10 h-10 bg-teal-400/10 rounded-lg">
      <IoEarthOutline className="text-teal-400 text-2xl" />
    </div>
    <h3 className="text-xl font-semibold text-white text-center">
      Country Specific Pricing (A-Z prep plan)
    </h3>
  </div>
  
  {/* Responsive Container */}
  <div className="flex flex-wrap sm:flex-nowrap justify-center items-center gap-6 w-full">
    <CountryBadge flag="🇯🇵" name="Japan" price="1,350" />
    <CountryBadge flag="🇦🇺" name="Australia" price="2,600" isSpecial={true} />
  </div>
</div>


      </div>
    </section>
  );
};
// Add this new component for the tooltip
const FeatureWithTooltip = ({ feature }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <li className="flex items-start relative">
      <FiCheck className="text-teal-400 mt-1 mr-3" />
      <span className="text-gray-300 flex-grow">{feature.text}</span>
      <button 
        className="text-gray-400 hover:text-teal-400 ml-2"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <FiInfo size={16} />
        {showTooltip && (
          <div className="absolute left-1/2 top-full mt-2 p-2 bg-gray-800 text-sm text-white rounded-lg shadow-lg z-50 w-70">
            {feature.tooltip}
          </div>
        )}
      </button>
    </li>
  );
};

export default PricingSection;